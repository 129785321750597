import React, { Component } from 'react';
import axios from 'axios';
import TagManager from 'react-gtm-module';
import { HttpService } from '../services';

const AppContext = React.createContext();

export class AppProvider extends Component {
    state = {};
    tagManagerArguments = {};

    componentDidMount() {
        return axios.get('/config.json').then(config => {
            HttpService.setConfig(config.data);

            this.setState({ config: config.data });

            this.tagManagerArguments = {
                gtmId: config.data.googleTagManagerId,
                auth: config.data.googleTagManagerAuth,
                preview: config.data.googleTagManagerEnvironment
            };

            TagManager.initialize(this.tagManagerArguments);
        });
    }

    render() {
        const { config } = this.state;

        return (
            <React.Fragment>
                {config && (
                    <AppContext.Provider
                        value={{
                            state: {
                                config
                            }
                        }}
                    >
                        {this.props.children}
                    </AppContext.Provider>
                )}
            </React.Fragment>
        );
    }
}

export const AppConsumer = AppContext.Consumer;
