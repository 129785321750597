import React, { Component } from 'react';

import { HttpService } from '../../services';
import { AuthConsumer } from '../../contexts';
import { TableColumnType, Modal, CustomersDropdown, StaticTable, DeviceTypes } from '../../components';
import { BoxTypes } from './BoxTypes';

const TableFooter = ({ data }) => {
    let total = data.reduce(
        (current, accumulator) => {
            const registeredDevices = current.registeredDevices + accumulator.registeredDevices;
            const unregisteredDevices = current.unregisteredDevices + accumulator.unregisteredDevices;
            const totalDevicesInTheBox = current.totalDevicesInTheBox + accumulator.totalDevicesInTheBox;
            return { registeredDevices, unregisteredDevices, totalDevicesInTheBox };
        },
        { registeredDevices: 0, unregisteredDevices: 0, totalDevicesInTheBox: 0 }
    );

    return (
        <tfoot>
            <tr className="pointer">
                <td />
                <td />
                <td />
                <td>TOTAL</td>
                {Object.keys(total).map(key => (
                    <td key={key}>{total[key]}</td>
                ))}
            </tr>
        </tfoot>
    );
};

export class StockSummary extends Component {
    state = {
        filter: {
            customerId: this.props.match.params.customerId
        },
        moveBoxes: false,
        customerName: null,
        stockSummaryItems: null,
        originalStockSummaryItems: null,
        tableColumns: [
            {
                name: 'palletNumber',
                text: 'Pallet No.',
                dataType: TableColumnType.Text,
                orderBy: true,
                groupBy: true
            },
            {
                name: 'boxAllocatedDate',
                text: 'Box Allocated Date',
                dataType: TableColumnType.DateTime
            },
            {
                name: 'defaultFirmwareVersion',
                text: 'Default FVV',
                dataType: TableColumnType.Text
            },
            {
                name: 'registeredDevices',
                text: 'Registered devices',
                dataType: TableColumnType.Text
            },
            {
                name: 'unregisteredDevices',
                text: 'UnRegistered devices',
                dataType: TableColumnType.Text
            },
            {
                name: 'totalDevicesInTheBox',
                text: 'Total devices',
                dataType: TableColumnType.Text
            }
        ]
    };

    componentDidMount() {
        this.getStockSummary();
    }

    onSingleSelect = selectedId => {
        this.state.originalStockSummaryItems.forEach(item => {
            if (item.id === selectedId) {
                item.selected = !item.selected;
            }
        });

        this.setState({ stockSummaryItems: [...this.state.originalStockSummaryItems] });
    };

    onSelectAll = selectAll => {
        this.state.originalStockSummaryItems.forEach(item => {
            item.selected = !!selectAll;
        });
        this.setState({ stockSummaryItems: [...this.state.originalStockSummaryItems] });
    };

    deviceTypeChanged = deviceType => {
        this.resetItemSelection();

        this.setState(prevState => {
            return { filter: Object.assign(prevState.filter, { deviceType }) };
        }, this.getStockSummary());
    };

    onBoxTypeChange = boxType => {
        this.resetItemSelection();

        this.setState(prevState => {
            return { filter: Object.assign(prevState.filter, { boxType }) };
        }, this.getStockSummary());
    };

    resetItemSelection = () => {
        this.state.originalStockSummaryItems.forEach(item => {
            item.selected = false;
        });
    };

    selectedItems = () => {
        const selectedItems = this.state.originalStockSummaryItems.filter(i => i.selected);
        if (selectedItems && selectedItems.length > 0) {
            return selectedItems;
        }
        return null;
    };

    SelectedItemsTemplate = () => {
        return (
            <div>
                <ul>
                    {this.selectedItems().map(item => {
                        return <li key={item.boxNumber}>{item.boxNumber}</li>;
                    })}
                </ul>
                <CustomersDropdown
                    onChange={customerId => (this.newCustomerId = customerId)}
                    hideCustomer={this.props.match.params.customerId}
                />
            </div>
        );
    };

    onMoveBoxesStart = () => {
        this.setState(prevState => {
            return { moveBoxes: !prevState.moveBoxes };
        });
    };

    moveBoxesConfirm = () => {
        const customerId = this.props.match.params.customerId;
        const boxIds = this.selectedItems().map(item => item.boxNumber);
        HttpService.post(`/Stock/MoveDeviceBoxesToAnotherCustomer`, {
            existingCustomerId: customerId,
            newCustomerId: this.newCustomerId,
            deviceBoxIds: boxIds
        }).then(() => {
            this.setState(prevState => {
                return { moveBoxes: !prevState.moveBoxes };
            }, this.getStockSummary());
        });
    };

    getStockSummary = () => {
        HttpService.get(`/Stock/GetStockSummary`, this.state.filter).then(response => {
            const { customerName, stockSummaryItems } = response.data;
            stockSummaryItems.forEach(item => {
                item.id = item.boxNumber;
                item.selected = false;
            });
            this.setState({
                customerName,
                stockSummaryItems: stockSummaryItems,
                originalStockSummaryItems: [...stockSummaryItems]
            });
        });
    };

    allowSelection = (row, hasInventoryRole, hasSystemAdminRole) => {
        if (!hasInventoryRole && !hasSystemAdminRole) {
            return false;
        }

        return row.unregisteredDevices === row.totalDevicesInTheBox;
    };

    render() {
        const { customerName, stockSummaryItems, tableColumns, moveBoxes } = this.state;

        return (
            <AuthConsumer>
                {context => {
                    const { hasInventoryRole } = context.actions;
                    const { hasSystemAdminRole } = context.actions;
                    return (
                        <div>
                            {stockSummaryItems && (
                                <>
                                    <div className="row mb-3">
                                        <h2>STOCK SUMMARY for {`${customerName}`}</h2>
                                    </div>
                                    {
                                        <>
                                            <div className="row">
                                                <div className="dashboard">
                                                    <div className="dashboard-controls">
                                                        <div className="form-inline">
                                                            <div className="form-group">
                                                                <DeviceTypes onChange={this.deviceTypeChanged} />
                                                            </div>
                                                            <div className="form-group">
                                                                <BoxTypes onChange={this.onBoxTypeChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="action-buttons">
                                                    {this.selectedItems() && (hasInventoryRole() || hasSystemAdminRole()) && (
                                                        <button
                                                            className="btn brd-primary brd-primary-alt-state clr-primary clr-primary-alt-state"
                                                            onClick={this.onMoveBoxesStart}
                                                        >
                                                            MOVE {this.selectedItems().length} BOXES &nbsp;{' '}
                                                            <i className="fal fa-arrow-right" />
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                            {stockSummaryItems && stockSummaryItems.length > 0 ? (
                                                <StaticTable
                                                    columns={tableColumns}
                                                    data={stockSummaryItems}
                                                    onSingleSelect={this.onSingleSelect}
                                                    onSelectAll={this.onSelectAll}
                                                    allowSelection={row => this.allowSelection(row, hasInventoryRole, hasSystemAdminRole)}
                                                    allowSelectAll={false}
                                                    footer={
                                                        <TableFooter
                                                            data={stockSummaryItems}
                                                            isInventoryUser={hasInventoryRole() || hasSystemAdminRole()}
                                                        />
                                                    }
                                                />
                                            ) : (
                                                <div>NO RESULTS FOUND</div>
                                            )}
                                        </>
                                    }

                                    {moveBoxes && (
                                        <Modal
                                            title={`Move boxes`}
                                            message={`Move these boxes to another customer.`}
                                            onClose={this.moveBoxesConfirm}
                                            onCancel={this.onMoveBoxesStart}
                                        >
                                            {this.selectedItems() && this.SelectedItemsTemplate()}
                                        </Modal>
                                    )}
                                </>
                            )}
                        </div>
                    );
                }}
            </AuthConsumer>
        );
    }
}
