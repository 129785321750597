import React from 'react';

import { HttpService } from '../../services';
import { TableColumnType, DefaultPageNumber, DefaultPageSize, StaticTable } from '../../components';

export class DeviceAuditModal extends React.Component {
    state = {
        tableColumns: [
            {
                name: 'occurred',
                text: 'DATE & TIME',
                dataType: TableColumnType.DateTime
            },
            {
                name: 'action',
                text: 'EVENT TYPE',
                dataType: TableColumnType.Text
            },
            {
                name: 'description',
                text: 'DESCRIPTION',
                dataType: TableColumnType.Text
            },
            {
                name: 'triggeredBy',
                text: 'TRIGGERED BY',
                dataType: TableColumnType.Text
            }
        ],
        data: null,
        pageDetail: {
            pageSize: DefaultPageSize,
            pageNumber: DefaultPageNumber
        }
    };

    componentDidMount() {
        const { pageNumber, pageSize } = this.state.pageDetail;
        this.getAudits(pageNumber, pageSize);
    }

    changePage = pageNumber => {
        const { pageSize } = this.state.pageDetail;
        this.getAudits(pageNumber, pageSize);
    };

    changePerPage = newPageSize => {
        const { pageNumber } = this.state.pageDetail;
        this.getAudits(pageNumber, newPageSize);
    };

    getAudits = (pageNumber, pageSize) => {
        const { idOfDeviceToAudit } = this.props;
        let params = {
            deviceId: idOfDeviceToAudit,
            pageNumber,
            pageSize
        };

        HttpService.get('/Device/GetAuditHistory', params).then(response => {
            const { result, pageDetail } = response.data;
            this.setState({
                data: result,
                pageDetail: pageDetail
            });
        });
    };
    render() {
        const { tableColumns, data, pageDetail } = this.state;
        return (
            <>
                <div className="modal-backdrop fade in show" />
                <div className="modal fade in show">
                    <div className="modal-dialog device-audit">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">DEVICE AUDIT</h4>
                                <button onClick={this.props.onClose} className="close" data-dismiss="modal" aria-label="Close">
                                    <i className="fal fa-times" />
                                </button>
                            </div>
                            <div className="modal-body">
                                {data && data.length > 0 ? (
                                    <StaticTable
                                        tableText={{ perPageLabel: 'Audits per page' }}
                                        columns={tableColumns}
                                        data={data}
                                        changePage={this.changePage}
                                        pageDetail={pageDetail}
                                        changePerPage={this.changePerPage}
                                    />
                                ) : (
                                    <div className="audit-not-found">
                                        <i className="fas fa-exclamation" />
                                        <h3>Sorry, no audit records were found.</h3>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <div className="modal-btn-container">
                                    <button onClick={this.props.onClose} className="btn btn-error">
                                        CLOSE &nbsp; <i className="fal fa-times" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
