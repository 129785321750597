import React from 'react';

export const TablePageSize = ({ perPageLabel, pageDetail, changePerPage }) => {
    const onChangePerPage = e => {
        const newPageSize = parseFloat(e.target.value);
        changePerPage(newPageSize);
    };

    return (
        <div className="table-page-size-dropdown">
            <label htmlFor="table-page-size">{perPageLabel}: &nbsp;</label>
            <select value={pageDetail.pageSize} onChange={onChangePerPage} id="table-page-size" name="table-page-size">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
            </select>
        </div>
    );
};
