import React, { useState, Component } from 'react';
import { HttpService } from '../../services';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { addDays } from 'date-fns';
import { addYears } from 'date-fns';
import Papa from 'papaparse/papaparse.min.js';

export const SimCardActivation = () => {
    const [devices, setDevices] = useState([]);
    const [result, setResult] = useState([]);
    const [successCount, setSuccessCount] = useState();
    const [startDate, setStartDate] = useState(addYears(new Date(), 1));
    const [expiryDisplay, setExpiryDisplay] = useState(moment.utc(startDate).format('dddd, LL'));
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);

    const processData = (data, order) => {
        var headers = ['Device ID'];
        if (order == 'Upload') {
            headers = ['Device ID', 'Reason For Failure'];
        }

        const list = [];
        for (let i = 0; i < data.length; i++) {
            const row = data[i];
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let item = row[j];
                    if (row[j] != '') {
                        devices.push(row);
                    }
                    if (item.length > 0) {
                        if (item[0] == '"') item = item.substring(1, item.length - 1);
                        if (item[item.length - 1] == '"') item = item.substring(item.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = item;
                    }
                }

                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }

        const columns = headers.map(c => ({
            name: c,
            selector: c,
            sortable: true,
            minWidth: '10px'
        }));

        setDevices(devices);
        setData(list);
        setColumns(columns);
    };

    const handleFileLoad = e => {
        const file = e.target.files[0];
        if (file != null) {
            setSubmitDisabled(true);
            setIsDisabled(false);
            Papa.parse(file, {
                skipEmptyLines: true,
                complete: function(results) {
                    processData(results.data, 'Load');
                }
            });
        }
    };

    const handleFileUpload = () => {
        var deviceIds = [];
        for (let index = 0; index < devices.length; index++) {
            const element = devices[index];
            deviceIds.push(element[0]);
        }
        if (devices.length != 0) {
            setSubmitDisabled(true);
            setIsDisabled(true);
            HttpService.post('/SimCard/BatchActivateSimCard', { Devices: deviceIds, SimCardExpiry: startDate }).then(response => {
                if (response.status == 200) {
                    var responseData = [];
                    var count = 0;
                    setSuccessCount('Successful Devices: ' + 0);
                    setData();
                    for (let index = 0; index < response.data.length; index++) {
                        if (response.data[index].commandSucceeded == true) {
                            count++;
                            setSuccessCount('Successful Devices: ' + count);
                        } else {
                            setResult('Failed Devices');
                            responseData.push([deviceIds[index], response.data[index].stateDescription]);
                        }
                    }
                    processData(responseData, 'Upload');
                }
            });
        }
    };

    const customStyles = {
        headCells: {
            style: {
                fontSize: '18px'
            },
            cells: {
                fontSize: '16px'
            }
        }
    };

    const CustomInput = ({ value, onClick }) => (
        <button class="btn brd-primary clr-primary btn-border" style={{ 'margin-right': '10px' }} disabled={isDisabled} onClick={onClick}>
            Sim Expiry Date
        </button>
    );

    const handleDateChange = newdate => {
        setStartDate(newdate);
        setExpiryDisplay(moment.utc(newdate).format('dddd, LL'));
    };

    const reset = () => {
        window.location.reload(false);
    };

    const downloadTxtFile = () => {
        const element = document.createElement('a');
        const file = new Blob(['DeviceId1\nDeviceId2\nDeviceId3'], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'Batch Sim Card Activation Template.csv';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    };

    return (
        <div className="dashboard">
            <h2>SIM CARD ACTIVATION</h2>
            <p>Please submit a file containing the list of device ids in a carriage return delimited format.</p>
            <p>Invalid device ids will be skipped.</p>

            <br />
            <form>
                <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileLoad} disabled={submitDisabled} />
                <input type="reset" defaultValue="Reset" class="btn brd-primary clr-primary btn-border" onClick={reset} />
            </form>
            <br />
            <div className="row">
                <button
                    disabled={isDisabled}
                    class="btn brd-green clr-green btn-border"
                    style={{ 'margin-right': '10px' }}
                    onClick={handleFileUpload}
                >
                    Submit File
                </button>
                <DatePicker
                    selected={startDate}
                    onChange={date => handleDateChange(date)}
                    minDate={addDays(new Date(), 1)}
                    showMonthDropdown
                    showYearDropdown
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Sim Expiry Date"
                    withPortal
                    customInput={<CustomInput />}
                />
                <button onClick={downloadTxtFile} class="btn brd-primary clr-primary btn-border" style={{ 'margin-right': '10px' }}>
                    Download File Template
                </button>
            </div>
            <br />
            {'Sim Expiry UTC Date: ' + expiryDisplay}
            <br />
            {successCount}
            <DataTable title={result} pagination highlightOnHover columns={columns} data={data} customStyles={customStyles} responsive />
        </div>
    );
};
