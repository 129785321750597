import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { MultiSelectDropdown } from '../../components';
import { DevicesContext, AuthContext } from '../../contexts';
import { HttpService } from '../../services';
import { sortBy } from 'lodash';
import { AuthConsumer } from '../../contexts';

export const EditDevice = ({ match, history }) => {
    const [device, setDevice] = useState(null);
    const [groups, setGroups] = useState(null);
    const [dropDownGroups, setDropDownGroups] = useState([]);
    const [productLifecycleStages, setProductLifecycleStages] = useState(null);
    const { state } = useContext(AuthContext);
    const user = state.user;
    const { devicesState } = useContext(DevicesContext);
    const { customers } = devicesState;

    useEffect(() => {
        Promise.all([
            HttpService.get('/Device/GetDevice', { id: match.params.id }),
            HttpService.get('/Group'),
            HttpService.get('/ProductLifecycleStage/GetAlProductLifecycleStages')
        ]).then(([deviceResponse, groupsResponse, productLifecycleStagesResponse]) => {
            const device = deviceResponse.data;
            device.productLifecycleStageId = device.productLifecycleStage.id;
            device.groups = device.groups.filter(g => g.groupByCustomerId === +user.profile.CompanyId);
            setDevice(device);
            setGroups(groupsResponse.data);
            setProductLifecycleStages(productLifecycleStagesResponse.data);
        });
    }, []);

    useEffect(() => {
        if (groups && device.groups) {
            const ddlGroups = groups.filter(g => device && device.groups.every(dg => dg.id !== g.id));
            setDropDownGroups(ddlGroups);
        }
    }, [groups, device]);

    const updateDeviceProperty = (property, value) => {
        const updatedDevice = { ...device };
        updatedDevice[property] = value;
        setDevice(updatedDevice);
    };

    const updateDevice = () => {
        HttpService.post('/Device/UpdateDevice', device).then(response => history.push('/Devices'));
    };

    const onMultiSelectDropdownChange = (newValues, dropwDownValues) => {
        setDevice({ ...device, groups: newValues });
        setDropDownGroups(dropwDownValues);
    };

    const productLifecycleStageName =
        device && productLifecycleStages
            ? productLifecycleStages.filter(stage => stage.id === device.productLifecycleStage.id)[0].stageName
            : '';

    const rolescheck = authContext => {
        const { hasSystemAdminRole } = authContext.actions;
        const { hasDeviceManagerRole } = authContext.actions;
        var hasDeviceRole = hasDeviceManagerRole() || hasSystemAdminRole();
        return [
            {
                hide: !hasDeviceRole
            }
        ];
    };
    return (
        <>
            {device && groups && (
                <AuthConsumer>
                    {context => {
                        return (
                            <>
                                {rolescheck(context).map(div => (
                                    <div className="container" hidden={div.hide}>
                                        <h1>Edit device {device.Id}</h1>
                                        <form>
                                            <div className="form-group">
                                                <label htmlFor="device-id">DEVICE ID</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="device-id"
                                                    name="device-id"
                                                    value={device.deviceId}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="device-type">DEVICE TYPE</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="device-type"
                                                    name="device-type"
                                                    value={device.deviceType}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="customer">CUSTOMER</label>
                                                <select
                                                    className="form-control"
                                                    id="customer"
                                                    name="customer"
                                                    value={device.customerId}
                                                    onChange={event => updateDeviceProperty('customerId', event.target.value)}
                                                >
                                                    <option value="" disabled="disabled">
                                                        SELECT
                                                    </option>
                                                    {customers &&
                                                        customers.map(customer => (
                                                            <option key={customer.id} value={customer.id}>
                                                                {customer.customerName}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>

                                            <div className="form-group">
                                                <label>PRODUCT LIFECYCLE STAGE</label>
                                                <div className="select-wrapper filter-control">
                                                    <input
                                                        readOnly
                                                        type="text"
                                                        className="form-control"
                                                        id="product-lifecycle-stage"
                                                        name="product-lifecycle-stage"
                                                        value={productLifecycleStageName}
                                                    />
                                                </div>
                                            </div>

                                            <MultiSelectDropdown
                                                label="ASSIGN GROUPS"
                                                inputValues={sortBy(device.groups, 'groupName')}
                                                dropDownValues={sortBy(dropDownGroups, 'groupName')}
                                                keyIdentifier="id"
                                                valueIdentifier="groupName"
                                                onChange={(newInputValues, newDropDownValues) =>
                                                    onMultiSelectDropdownChange(newInputValues, newDropDownValues)
                                                }
                                            />

                                            <div className="btn-container">
                                                <button type="button" onClick={updateDevice} className="btn btn-success">
                                                    UPDATE DEVICE &nbsp; <i className="fal fa-plus" />
                                                </button>
                                                <Link to="/Devices" className="btn btn-error">
                                                    CANCEL &nbsp; <i className="fal fa-times" />
                                                </Link>
                                            </div>
                                        </form>
                                    </div>
                                ))}
                            </>
                        );
                    }}
                </AuthConsumer>
            )}
        </>
    );
};
