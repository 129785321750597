import React from 'react';
import { HttpService } from '../../services';

export class UpdateFirmwareModal extends React.Component {
    state = { firmwares: [], firmwareId: '' };

    componentDidMount() {
        const { selected, devices } = this.props;
        var deviceTypesTmp = new Set();
        devices.forEach(result => {
            if (selected.selectedIds.includes(result.id)) {
                deviceTypesTmp.add(result.deviceType);
            }
        });
        let deviceTypes = [];
        deviceTypesTmp.forEach(result => {
            deviceTypes.push(result);
        });
        if (deviceTypes.length == 1) {
            HttpService.get('/Firmware/GetAllFirmwaresFilter?deviceType=' + deviceTypes[0]).then(response =>
                this.setState({ firmwares: response.data })
            );
        }
    }

    updateFirmwareId = firmwareId => this.setState({ firmwareId });

    onUpdateFirmware = () => {
        const firmware = this.state.firmwares.find(x => x.id == this.state.firmwareId);
        const { selectedIds } = this.props.selected;
        HttpService.post('/Firmware/UpdateFirmware', {
            filter: { selectedIds },
            firmwareId: this.state.firmwareId
        }).then(() => this.props.onSuccess(firmware && firmware.firmwareVersion));
    };

    render() {
        const { selected, selectedDeviceTypes, devices, onClose } = this.props;
        const { firmwares, firmwareId } = this.state;

        var deviceTypes = new Set(selectedDeviceTypes);
        if (deviceTypes.size > 1) {
            return (
                <React.Fragment>
                    <div className="modal-backdrop fade in show" />
                    <div className="modal fade in show">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">UPDATE FIRMWARE</h4>
                                    <button onClick={onClose} className="close" data-dismiss="modal" aria-label="Close">
                                        <i className="fal fa-times" />
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>
                                        {`The selected devices are of multiple device types. You can only select devices of the same type to be updated.`}
                                    </p>
                                </div>
                                <div className="modal-footer">
                                    <div className="modal-btn-container">
                                        <button onClick={onClose} className="btn btn-error">
                                            CANCEL &nbsp; <i className="fal fa-times" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="modal-backdrop fade in show" />
                    <div className="modal fade in show">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">UPDATE FIRMWARE</h4>
                                    <button onClick={onClose} className="close" data-dismiss="modal" aria-label="Close">
                                        <i className="fal fa-times" />
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>
                                        {`Select firmware version to update ${selected.selectedCount} 
                                    ${selected.selectedCount === 1 ? 'device' : 'devices'}.`}
                                    </p>
                                    <br />
                                    <div className="form-group">
                                        <label htmlFor="customer">FIRMWARE VERSION</label>
                                        <select
                                            className="form-control"
                                            id="firmware"
                                            name="firmware"
                                            value={firmwareId}
                                            onChange={event => this.updateFirmwareId(event.target.value)}
                                        >
                                            <option value="" disabled="disabled">
                                                SELECT
                                            </option>
                                            {firmwares.map(firmware => (
                                                <option key={firmware.id} value={firmware.id}>
                                                    {firmware.firmwareVersion}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="modal-btn-container">
                                        <button onClick={this.onUpdateFirmware} disabled={!firmwareId} className="btn btn-success">
                                            UPDATE FIRMWARE &nbsp; <i className="fal fa-cloud-upload" />
                                        </button>
                                        <button onClick={onClose} className="btn btn-error">
                                            CANCEL &nbsp; <i className="fal fa-times" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }
}
