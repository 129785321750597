import axios from 'axios';

const axiosInstance = axios.create();

let outstandingRequests = 0;

const resetRequest = error => {
    outstandingRequests = 0;
    if (document.location.pathname != '/NotAuthorised') {
        document.body.children.root.lastChild.classList.add('loading-indicator');
    }
    return Promise.reject(error);
};

axiosInstance.interceptors.request.use(config => {
    outstandingRequests++;
    if (document.location.pathname != '/NotAuthorised') {
        document.body.children.root.lastChild.classList.add('loading-indicator');
    }
    return config;
}, resetRequest);

axiosInstance.interceptors.response.use(response => {
    outstandingRequests--;
    if (outstandingRequests === 0) {
        document.body.children.root.lastChild.classList.remove('loading-indicator');
    }
    return response;
}, resetRequest);

let config;
let accessToken;

const get = (url, params) => {
    const headers = getHeaders();
    return axiosInstance.get(`${config.apiUrl}${url}`, { params, headers });
};

const post = (url, data) => {
    const headers = getHeaders();
    return axiosInstance.post(`${config.apiUrl}${url}`, data, { headers });
};

const put = (url, data) => {
    const headers = getHeaders();
    return axiosInstance.put(`${config.apiUrl}${url}`, data, { headers });
};

const del = url => {
    const headers = getHeaders();
    return axiosInstance.delete(`${config.apiUrl}${url}`, { headers });
};

const getHeaders = () => {
    return accessToken ? { Authorization: accessToken } : {};
};

const setAccessToken = user => {
    accessToken = user && !user.expired && `${user.token_type} ${user.access_token}`;
};

const setConfig = newConfig => {
    config = newConfig;
};

const getConfig = () => {
    return config;
};

const HttpService = {
    get,
    post,
    put,
    delete: del,
    setAccessToken,
    setConfig,
    getConfig
};

export { HttpService };
