import React from 'react';

export class ResponseModal extends React.Component {
    render() {
        if (this.props.responseState == '') {
            var responseState = 'No errors detected';
        } else {
            var responseState = this.props.responseState;
        }

        return (
            <>
                <div className="modal-backdrop fade in show" />
                <div className="modal fade in show">
                    <div className="modal-dialog device-audit">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Response</h4>
                                <button onClick={this.props.onClose} className="close" data-dismiss="modal" aria-label="Close">
                                    <i className="fal fa-times" />
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.props.responseText} <br />
                                <br />
                                Response from server: <br />
                                {responseState}
                            </div>
                            <div className="modal-footer">
                                <div className="modal-btn-container">
                                    <button onClick={this.props.onClose} className="btn btn-error">
                                        CLOSE &nbsp; <i className="fal fa-times" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
