const getReturnUrl = () => {
    return getObject('returnUrl');
};

const setReturnUrl = returnUrl => {
    setObject('returnUrl', returnUrl);
};

const getObject = key => {
    const obj = sessionStorage.getItem(key);
    return !obj || obj === 'undefined' ? null : JSON.parse(obj);
};

const setObject = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
};

const clear = keepConfig => {
    const config = sessionStorage.getItem('config');
    sessionStorage.clear();
    if (keepConfig) {
        sessionStorage.setItem('config', config);
    }
};

const SessionService = {
    getReturnUrl,
    setReturnUrl,
    getObject,
    setObject,
    clear
};

export { SessionService };
