const cookiesAcceptedKey = 'cookiesAccepted';

const getCookiesAccepted = () => {
    let cookiesAccepted = getObject(cookiesAcceptedKey);
    if (!cookiesAccepted) {
        cookiesAccepted = false;
        setCookiesAccepted(cookiesAccepted);
    }
    return cookiesAccepted;
};

const setCookiesAccepted = cookiesAccepted => {
    setObject(cookiesAcceptedKey, cookiesAccepted);
};

const getObject = key => {
    const obj = localStorage.getItem(key);
    return !obj || obj === 'undefined' ? null : JSON.parse(obj);
};

const setObject = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

const removeObject = key => {
    localStorage.removeItem(key);
};

const clear = () => {
    localStorage.clear();
};

const LocalStorageService = {
    getCookiesAccepted,
    setCookiesAccepted,
    getObject,
    setObject,
    removeObject,
    clear
};

export { LocalStorageService };
