import React from 'react';

import DatePicker from 'react-datepicker';
import { addDays } from 'date-fns';

export class DeviceSimExpireDateModal extends React.Component {
    state = {
        deviceId: this.props.idOfDevice,
        date: null
    };

    handleDateChange(newDate) {
        this.setState({ date: newDate });
    }

    submit() {
        this.props.expiryDate(this.state.date);
        this.props.onClose();
    }

    render() {
        return (
            <>
                <div className="modal-backdrop fade in show" />
                <div className="modal fade in show">
                    <div className="modal-dialog sim-expiry">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">IC7 Sim Card Expiry Date</h4>
                                <button onClick={this.props.onClose} className="close" data-dismiss="modal" aria-label="Close">
                                    <i className="fal fa-times" />
                                </button>
                            </div>
                            <div className="modal-body" style={{ minHeight: '320px' }}>
                                Device: {this.props.idOfDevice}
                                <br />
                                <DatePicker
                                    selected={this.state.date}
                                    onChange={date => this.handleDateChange(date)}
                                    minDate={addDays(new Date(), 1)}
                                    showMonthDropdown
                                    showYearDropdown
                                    inline
                                />
                            </div>
                            <div className="modal-footer">
                                <div className="modal-btn-container">
                                    <button onClick={props => this.submit(this.props)} className="btn btn-success">
                                        SUBMIT &nbsp; <i className="fal fa-check" />
                                    </button>
                                    <button onClick={this.props.onClose} className="btn btn-error">
                                        CLOSE &nbsp; <i className="fal fa-times" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
