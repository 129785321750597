import React from 'react';
import ReactDOM from 'react-dom';
import './styles/style.scss';
import App from './App';
import registerServiceWorker, { unregister } from './registerServiceWorker';

const rootElement = document.getElementById('root');

ReactDOM.render(<App />, rootElement);

// Enables hot module replacement - only in DEV mode.
if (module.hot) {
    module.hot.accept('./App', () => {
        console.clear();
        const NextApp = require('./App').default;
        ReactDOM.render(<NextApp />, rootElement);
    });
}

// registerServiceWorker();
unregister();
