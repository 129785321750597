import React, { useState, useEffect } from 'react';
import { HttpService } from '../services';

const DeviceTypes = ({ onChange }) => {
    const [deviceTypes, setDeviceTypes] = useState([]);

    useEffect(() => {
        let mounted = true;
        HttpService.get('/DeviceType/GetAllDeviceTypes').then(response => mounted && setDeviceTypes(response.data));
        return () => {
            mounted = false;
        };
    }, []);

    return (
        <>
            <label htmlFor="inlineFormInput">DEVICE TYPE</label>
            <select className="form-control clr-primary brd-primary" onChange={event => onChange(event.target.value)}>
                <option value="">All</option>
                {deviceTypes.map((deviceType, index) => (
                    <option key={`${deviceType}}${index}`} value={deviceType}>
                        {deviceType}
                    </option>
                ))}
            </select>
        </>
    );
};

export { DeviceTypes };
