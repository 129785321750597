import React from 'react';

export const RightPane = props => {
    const { isVisible, title, message, confirmBtnText, confirmBtnFn, cancelBtnText, cancelBtnFn, commandId, commandChangeFn } = props;

    return (
        <div className={`sidebar-right ${isVisible ? 'right-pane-expanded' : ''}`}>
            <h2>{title}</h2>

            <select
                className="form-control"
                id="command"
                name="command"
                value={commandId}
                onChange={event => commandChangeFn(event.target.value)}
            >
                <option key="recalibrate" value="recalibrate">
                    RECALIBRATE
                </option>
                <option key="reboot" value="reboot">
                    REBOOT
                </option>
            </select>

            <p>{message}</p>

            <button onClick={cancelBtnFn} className="btn btn-error">
                {cancelBtnText || 'Cancel'} &nbsp; <i className="fal fa-ban" />
            </button>

            <button onClick={confirmBtnFn} className="btn btn-success" id="btn-ok">
                {confirmBtnText || 'OK'} &nbsp; <i className="fal fa-check" />
            </button>
        </div>
    );
};
