import React from 'react';

import { Modal, DynamicTable } from '../../components';
import { HttpService, UtilityService } from '../../services';
import { AuthConsumer, DevicesContext } from '../../contexts';
import { DeviceAuditModal } from './DeviceAuditModal';
import { EditDevicesModal } from './EditDevicesModal';
import { UpdateFirmwareModal } from './UpdateFirmwareModal';
import { UpdateOctafishFirmwareModal } from './UpdateOctafishFirmwareModal';
import { DeviceDetailsPanel } from './DeviceDetailsPanel';
import { deviceConstants } from './device-constants';
import { DeviceSimExpireDateModal } from './DeviceSimExpireDateModal';
import { ResponseModal } from './ResponseModal';
import { DeviceMetaDataHistoryModal } from './DeviceMetaDataHistoryModal';
import { DeviceConfigurationModal } from './DeviceConfigurationModal';
import { DeviceAnalyticsModal } from './DeviceAnalyticsModal';

export class Devices extends React.Component {
    static contextType = DevicesContext;

    state = {
        deviceTypes: new Set(),
        rightPaneExpanded: false,
        showDeviceAuditModal: false,
        showDeviceSimExpireDateModal: false,
        updateConfiguration: false,
        commandId: 'recalibrate',
        modalError: ''
    };

    table = React.createRef();
    deviceConfigInputRef = React.createRef();

    processResults = results => {
        this.setState({ devices: results });
        results.forEach(result => {
            const alertMessages = [];

            // Collect all relevant alert messages based on conditions
            if (result.isWarrantyOverNumberOfMonthsManufactured) {
                alertMessages.push('Manufacturing warranty void');
            }
            if (result.isWarrantyMonthsSinceFirstPairingOverX) {
                alertMessages.push('Paired duration exceeded');
            }
            if (result.isWarrantyOverXHoursDriven) {
                alertMessages.push('Hours driven limit exceeded');
            }
            if (result.isWarrantyOverXMilesDriven) {
                alertMessages.push('Mileage driven limit exceeded');
            }
            if (result.isWarrantyNumberOfDaysPairedAllPolicies) {
                alertMessages.push('Duration since first paring exceeded');
            }

            if (alertMessages.length > 0) {
                const combinedAlertText = alertMessages.join('\n');
                this.addAlert(result, combinedAlertText, 'fal fa-exclamation-circle', 'clr-yellow');
            }

            // Tamper
            if (result.tamperedReason) {
                this.addAlert(result, 'This device may have been tampered with.', 'fal fa-exclamation-circle', 'clr-red');
            }

            // Firmware
            if (result.requestedFirmwareVersion) {
                if (result.requestedFirmwareVersionFailed) {
                    this.addAlert(
                        result,
                        `Firmware update to v. ${result.requestedFirmwareVersion} failed. View Device Logs for details.`,
                        'fal fa-cloud-upload',
                        'clr-red'
                    );
                } else {
                    if (result.deviceType == 'IC7.O') {
                        var firmwareList = result.requestedFirmwareVersion.split(',');
                        var applicationFirmware = firmwareList[0];
                        var GPSFirmware = firmwareList[1];
                        var GSMFirmware = firmwareList[2];
                        var RAMPFirmware = firmwareList[3];
                        var message = `Application firmware update to v. ${applicationFirmware} pending.`;
                        if (GPSFirmware != '' && GPSFirmware != null) {
                            message += `\nGPS Firmware update to v. ${GPSFirmware} pending.`;
                        }
                        if (GSMFirmware != '' && GSMFirmware != null) {
                            message += `\nGSM Firmware update to v. ${GSMFirmware} pending.`;
                        }
                        if (RAMPFirmware !== '' && RAMPFirmware != null) {
                            message += `\nRAMP Firmware update to v. ${RAMPFirmware} pending.`;
                        }
                        this.addAlert(result, message, 'fal fa-cloud-upload', 'clr-dark-blue');
                    } else {
                        this.addAlert(
                            result,
                            `Firmware update to v. ${result.requestedFirmwareVersion} pending.`,
                            'fal fa-cloud-upload',
                            'clr-dark-blue'
                        );
                    }
                }
            }

            // Sim card
            if (result.deviceSimCardDetails != null) {
                if (result.deviceSimCardDetails.isSimCardActive == true) {
                    this.addAlert(result, ``, '', '');
                } else {
                    this.addAlert(result, ``, '', '');
                }
            }
        });
        return results;
    };

    addAlert = (result, text, iconClassName, colourClassName) => {
        if (!result.alerts) {
            result.alerts = [];
        }
        result.alerts.push({ text, iconClassName, colourClassName });
    };

    selectedChanged = selectState =>
        this.setState({ selected: selectState, deviceTypes: new Set(selectState.selectedDeviceTypes.map(x => x)) }, () =>
            this.context.devicesActions.updateSearchState(selectState)
        );

    toggleRightPane = () => this.setState({ rightPaneExpanded: !this.state.rightPaneExpanded });
    issueCommandClickHandler = () => this.toggleRightPane();
    toggleFirmwareModal = () => this.setState({ showUpdateFirmwareModal: !this.state.showUpdateFirmwareModal });
    toggleOctafishFirmwareModal = () => this.setState({ showUpdateOctafishFirmwareModal: !this.state.showUpdateOctafishFirmwareModal });
    toggleEditDevicesModal = () => this.setState({ showEditDevicesModal: !this.state.showEditDevicesModal });
    toggleDeviceAuditModal = deviceId =>
        this.setState({ showDeviceAuditModal: !this.state.showDeviceAuditModal, idOfDeviceToAudit: deviceId });
    toggleDeviceSimExpireDateModal = deviceId =>
        this.setState({ showDeviceSimExpireDateModal: !this.state.showDeviceSimExpireDateModal, idOfDevice: deviceId });
    toggleResponseModal = (text, state) => {
        const { filters, orderDetail, pageDetail } = this.context.devicesState.selectState;
        this.table.current.search(
            filters,
            pageDetail.pageNumber,
            pageDetail.pageSize,
            orderDetail.orderByColumn,
            orderDetail.orderByDirection
        );
        this.setState({ showResponseModal: !this.state.showResponseModal, responseText: text, responseState: state });
    };
    toggleDeviceMetaDataHistoryModal = deviceId =>
        this.setState({ showDeviceMetaDataHistoryModal: !this.state.showDeviceMetaDataHistoryModal, idOfDevice: deviceId });
    toggleAnalyticsModal = deviceId => this.setState({ showAnalyticsModal: !this.state.showAnalyticsModal, idOfDevice: deviceId });
    toggleConfigurationModal = () => this.setState({ showConfigurationModal: !this.state.showConfigurationModal });
    closeConfirmModal = () => this.setState({ confirmTitle: '', confirmMessage: '' });
    changeCommandClickHandler = value => this.setState({ commandId: value });
    isHidden = isRole => {
        if (isRole && this.state.deviceTypes.size == 1 && this.state.deviceTypes.has('IC7.O')) {
            return false;
        } else {
            return true;
        }
    };

    deviceCommandsHandler = () => {
        const { selectedIds } = this.state.selected;
        HttpService.post('/Device/IssueCommand', {
            filter: { selectedIds },
            command: this.state.commandId
        }).then(this.toggleRightPane());
    };

    editDevices = () => {
        if (this.state.selected.selectedIds.length === 1) {
            this.props.history.push(`EditDevice/${this.state.selected.selectedIds[0]}`);
        }
        this.toggleEditDevicesModal();
    };

    firmwareUpdated = firmwareVersion => {
        this.setState(prevState => ({
            showUpdateFirmwareModal: false,
            showUpdateOctafishFirmwareModal: false,
            confirmTitle: 'FIRMWARE UPDATE REQUESTED',
            confirmMessage: `Firmware update to ${firmwareVersion} requested for ${prevState.selected.selectedCount} ${
                prevState.selected.selectedCount === 1 ? 'device' : 'devices'
            }.`
        }));

        const { filters, orderDetail, pageDetail } = this.context.devicesState.selectState;
        this.table.current.search(
            filters,
            pageDetail.pageNumber,
            pageDetail.pageSize,
            orderDetail.orderByColumn,
            orderDetail.orderByDirection
        );
    };

    configurationUpdated = updatedCount => {
        this.setState({
            showConfigurationModal: false,
            confirmTitle: 'DEVICE CONFIGURATION UPDATED',
            confirmMessage: `Configuration for ${updatedCount} ${updatedCount === 1 ? 'device' : 'devices'} updated successfully.`
        });

        const { filters, orderDetail, pageDetail } = this.context.devicesState.selectState;
        this.table.current.search(
            filters,
            pageDetail.pageNumber,
            pageDetail.pageSize,
            orderDetail.orderByColumn,
            orderDetail.orderByDirection
        );
    };

    devicesUpdated = updatedCount => {
        this.setState({
            showEditDevicesModal: false,
            confirmTitle: 'DEVICES UPDATED',
            confirmMessage: `${updatedCount} ${updatedCount === 1 ? 'device' : 'devices'} updated.`
        });
        const { filters, orderDetail, pageDetail } = this.context.devicesState.selectState;
        this.table.current.search(
            filters,
            pageDetail.pageNumber,
            pageDetail.pageSize,
            orderDetail.orderByColumn,
            orderDetail.orderByDirection
        );
    };

    updateConfigurationConfirm = () => {
        const config = this.deviceConfigInputRef.current.value;
        if (UtilityService.isValidJson(config)) {
            const { selected } = this.state;
            const deviceCount = selected.selectedCount;
            const disclaimerText = deviceConstants.disclaimerText.replace('{deviceCount}', deviceCount);
            if (window.confirm(disclaimerText)) {
                const { selectedIds } = this.state.selected;
                HttpService.post('/Device/UpdateConfiguration', {
                    filter: { selectedIds },
                    jsonConfiguration: config
                }).then(this.toggleConfigurationModal, this.handleError);
            }
        } else {
            this.setState({ modalError: deviceConstants.errorMessages.invalidJson });
        }
    };

    deviceActionButtons = authContext => {
        const {
            hasConfigurationRole,
            hasSystemAdminRole,
            hasDeviceManagerRole,
            hasFirmwareManagerRole,
            hasCommandsManagerRole
        } = authContext.actions;
        var hasAdminRole = hasSystemAdminRole();
        var hasConfigRole = hasConfigurationRole() || hasSystemAdminRole();
        var hasDeviceRole = hasDeviceManagerRole() || hasSystemAdminRole();
        var hasFirmwareRole = hasFirmwareManagerRole() || hasSystemAdminRole();
        var hasCommandsRole = hasCommandsManagerRole() || hasSystemAdminRole();
        return [
            {
                text: 'UPDATE CONFIGURATION',
                icon: 'cog',
                clickHandler: this.toggleConfigurationModal,
                hide: !hasConfigRole,
                elementId: 'btn-update-configuration'
            },
            {
                text: 'UPDATE FIRMWARE',
                icon: 'upload',
                clickHandler: this.toggleOctafishFirmwareModal,
                elementId: 'btn-update-firmware',
                hide: this.isHidden(hasAdminRole)
            },
            {
                text: 'UPDATE FIRMWARE',
                icon: 'upload',
                clickHandler: this.toggleFirmwareModal,
                elementId: 'btn-update-firmware',
                hide: !this.isHidden(hasAdminRole)
            },
            {
                text: 'ISSUE COMMAND',
                icon: 'compass',
                clickHandler: this.issueCommandClickHandler,
                elementId: 'btn-issue-command',
                hide: !hasCommandsRole
            },
            {
                text: 'EDIT DEVICE(S)',
                icon: 'cogs',
                clickHandler: this.editDevices,
                hide: !hasDeviceRole,
                elementId: 'btn-edit-devices'
            }
        ];
    };

    handleError = err => {
        if (err.response) {
            const status = err.response.status;
            if (status === 400) {
                switch (err.response.data) {
                    case 'InvalidJson':
                        this.setState({ modalError: deviceConstants.errorMessages.invalidJson });
                        break;
                    case 'VersionMissing':
                        this.setState({ modalError: deviceConstants.errorMessages.versionMissing });
                        break;
                    default:
                        const error = err.response.data.JsonConfiguration && err.response.data.JsonConfiguration[0];
                        this.setState({ modalError: error });
                        break;
                }
            } else {
                this.setState({ modalError: deviceConstants.errorMessages.unknownError });
            }
        }
    };

    ConfigurationInputTemplate = modalError => {
        return (
            <div className="form-group device-configuration-input">
                <label htmlFor="deviceConfigurationInput">Device configuration</label>
                <textarea
                    className={`form-control ${modalError ? 'is-invalid' : ''}`}
                    id="deviceConfigurationInput"
                    ref={this.deviceConfigInputRef}
                />
                <div className="invalid-feedback">{modalError}</div>
            </div>
        );
    };

    activateSIM(date) {
        HttpService.post('/SimCard/ActivateSimCard', { DeviceReference: this.state.idOfDevice, SimCardExpiry: date }).then(response => {
            if (response.status == 200) {
                if (response.data.commandSucceeded == true) {
                    this.toggleResponseModal('Sim card activated successfully.', response.data.stateDescription);
                } else {
                    this.toggleResponseModal(
                        'An error occurred while trying to activate the sim card, please contact an administrator.',
                        response.data.stateDescription
                    );
                }
            }
        });
    }

    render() {
        const {
            selected,
            devices,
            showUpdateFirmwareModal,
            showUpdateOctafishFirmwareModal,
            showEditDevicesModal,
            confirmTitle,
            confirmMessage,
            showDeviceAuditModal,
            showDeviceSimExpireDateModal,
            showResponseModal,
            showDeviceMetaDataHistoryModal,
            showAnalyticsModal,
            showConfigurationModal,
            modalError,
            rightPaneExpanded
        } = this.state;

        const { tableColumns, selectState } = this.context.devicesState;
        const { selectedCount, selectedDeviceTypes } = selectState;
        var noRolesStyle = { marginTop: '-70px' };

        return (
            <>
                {tableColumns && (
                    <>
                        <div className={`action-buttons ${rightPaneExpanded ? 'right-pane-expanded' : ''}`}>
                            <AuthConsumer>
                                {context => {
                                    return (
                                        <div className="row">
                                            {this.deviceActionButtons(context).map((btn, index) => (
                                                <button
                                                    key={index}
                                                    className="btn brd-primary brd-primary-alt-state clr-primary clr-primary-alt-state"
                                                    id={btn.elementId}
                                                    onClick={btn.clickHandler}
                                                    hidden={btn.hide}
                                                    disabled={!selectedCount}
                                                >
                                                    {btn.text} &nbsp; <i className={`fal fa-${btn.icon}`} />
                                                </button>
                                            ))}
                                        </div>
                                    );
                                }}
                            </AuthConsumer>
                        </div>
                        <AuthConsumer>
                            {context => {
                                const {
                                    hasConfigurationRole,
                                    hasDeviceManagerRole,
                                    hasSystemAdminRole,
                                    hasFirmwareManagerRole,
                                    hasCommandsManagerRole
                                } = context.actions;
                                var hasEditDeviceRole =
                                    hasConfigurationRole() ||
                                    hasDeviceManagerRole() ||
                                    hasSystemAdminRole() ||
                                    hasFirmwareManagerRole() ||
                                    hasCommandsManagerRole();
                                return (
                                    <div>
                                        {showUpdateFirmwareModal && (
                                            <UpdateFirmwareModal
                                                selected={selected}
                                                selectedDeviceTypes={selectedDeviceTypes}
                                                devices={devices}
                                                onClose={this.toggleFirmwareModal}
                                                onSuccess={firmwareVersion => this.firmwareUpdated(firmwareVersion)}
                                            />
                                        )}
                                        {showUpdateOctafishFirmwareModal && (
                                            <UpdateOctafishFirmwareModal
                                                selected={selected}
                                                selectedDeviceTypes={selectedDeviceTypes}
                                                devices={devices}
                                                onClose={this.toggleOctafishFirmwareModal}
                                                onSuccess={firmwareVersion => this.firmwareUpdated(firmwareVersion)}
                                            />
                                        )}
                                        {showEditDevicesModal && (
                                            <EditDevicesModal
                                                selected={selected}
                                                onClose={this.toggleEditDevicesModal}
                                                onSuccess={updatedCount => this.devicesUpdated(updatedCount)}
                                            />
                                        )}
                                        {showDeviceAuditModal && (
                                            <DeviceAuditModal
                                                idOfDeviceToAudit={this.state.idOfDeviceToAudit}
                                                onClose={this.toggleDeviceAuditModal}
                                            />
                                        )}
                                        {showDeviceSimExpireDateModal && (
                                            <DeviceSimExpireDateModal
                                                idOfDevice={this.state.idOfDevice}
                                                onClose={this.toggleDeviceSimExpireDateModal}
                                                expiryDate={date => this.activateSIM(date)}
                                            />
                                        )}
                                        {showResponseModal && (
                                            <ResponseModal
                                                responseText={this.state.responseText}
                                                responseState={this.state.responseState}
                                                onClose={this.toggleResponseModal}
                                            />
                                        )}
                                        {showDeviceMetaDataHistoryModal && (
                                            <DeviceMetaDataHistoryModal
                                                idOfDevice={this.state.idOfDevice}
                                                onClose={this.toggleDeviceMetaDataHistoryModal}
                                            />
                                        )}
                                        {showAnalyticsModal && (
                                            <DeviceAnalyticsModal idOfDevice={this.state.idOfDevice} onClose={this.toggleAnalyticsModal} />
                                        )}
                                        {showConfigurationModal && (
                                            <DeviceConfigurationModal
                                                selected={selected}
                                                onClose={this.toggleConfigurationModal}
                                                onSuccess={updatedCount => this.configurationUpdated(updatedCount)}
                                            />
                                        )}
                                        {confirmTitle && confirmMessage && (
                                            <Modal title={confirmTitle} message={confirmMessage} onClose={this.closeConfirmModal} />
                                        )}
                                        {hasEditDeviceRole && (noRolesStyle = null)}
                                        <div style={noRolesStyle}>
                                            <DynamicTable
                                                ref={this.table}
                                                enableSelection={hasEditDeviceRole}
                                                tableColumns={tableColumns}
                                                apiUrl={'/Device/GetDevices'}
                                                initialState={selectState}
                                                onSelectedChanged={this.selectedChanged}
                                                onProcessResults={this.processResults}
                                                rightPaneExpanded={this.state.rightPaneExpanded}
                                                toggleRightPane={this.toggleRightPane}
                                                deviceCommandsHandler={this.deviceCommandsHandler}
                                                changeCommandClickHandler={this.changeCommandClickHandler}
                                                toggleDeviceAuditModal={this.toggleDeviceAuditModal}
                                                toggleDeviceSimExpireDateModal={this.toggleDeviceSimExpireDateModal}
                                                toggleResponseModal={this.toggleResponseModal}
                                                toggleDeviceMetaDataHistoryModal={this.toggleDeviceMetaDataHistoryModal}
                                                toggleAnalyticsModal={this.toggleAnalyticsModal}
                                                tableText={{
                                                    totalCountLabel: 'Total Devices',
                                                    perPageLabel: 'Devices per page'
                                                }}
                                                filterButtonText="FILTER DEVICES"
                                                RowDetailsTemplate={DeviceDetailsPanel}
                                                preSetFilter={this.props.location.filters}
                                                defaultOrderColumn={4}
                                            />
                                        </div>
                                    </div>
                                );
                            }}
                        </AuthConsumer>
                    </>
                )}
            </>
        );
    }
}
