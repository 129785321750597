import React, { Component } from 'react';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import { LocalStorageService, SessionService, HttpService } from '../services';
import { Roles } from '../models';

export const AuthContext = React.createContext();

export class AuthProvider extends Component {
    state = {
        setFilters: this.setFilters
    };

    isLoggedIn() {
        return this.state.user && this.state.user.access_token && !this.state.user.expired;
    }

    setFilters(filter) {
        debugger;
        this.setState({ preSetFilter: filter });
    }

    login(register = false) {
        SessionService.setReturnUrl(window.location.pathname);
        if (register) {
            const extraQueryParams = { register };
            return this.userManager.signinRedirect({ extraQueryParams });
        } else {
            return this.userManager.signinRedirect();
        }
    }

    logout() {
        return this.userManager.signoutRedirect({ id_token_hint: this.state.user.id_token });
    }

    hasDeviceManagerRole() {
        return this.state.user && this.state.user.profile && this.state.user.profile.Roles.indexOf(Roles.DeviceManager) > -1;
    }
    hasFirmwareManagerRole() {
        return this.state.user && this.state.user.profile && this.state.user.profile.Roles.indexOf(Roles.FirmwareManager) > -1;
    }
    hasSystemAdminRole() {
        return this.state.user && this.state.user.profile && this.state.user.profile.Roles.indexOf(Roles.SystemAdmin) > -1;
    }

    hasCompanyAdminRole() {
        return this.state.user && this.state.user.profile && this.state.user.profile.Roles.indexOf(Roles.CompanyAdmin) > -1;
    }

    hasInventoryRole() {
        return this.state.user && this.state.user.profile && this.state.user.profile.Roles.indexOf(Roles.InventoryManager) > -1;
    }

    hasConfigurationRole() {
        return this.state.user && this.state.user.profile && this.state.user.profile.Roles.indexOf(Roles.ConfigurationManager) > -1;
    }

    hasCommandsManagerRole() {
        return this.state.user && this.state.user.profile && this.state.user.profile.Roles.indexOf(Roles.CommandsManager) > -1;
    }

    hasSimCardManagerRole() {
        return this.state.user && this.state.user.profile && this.state.user.profile.Roles.indexOf(Roles.SimCardManager) > -1;
    }

    hasAccessRole() {
        return (
            this.state.user &&
            this.state.user.profile &&
            (this.state.user.profile.Roles.indexOf(Roles.DMAccess) > -1 || this.state.user.profile.Roles.indexOf(Roles.SystemAdmin) > -1)
        );
    }
    changePassword() {
        const cookiesAccepted = LocalStorageService.getCookiesAccepted();
        window.location.href = `${HttpService.getConfig().userManagerAuthority}/account/changepassword?&returnUrl=${
            window.location.origin
        }?cookiesAccepted=${cookiesAccepted}`;
    }

    componentDidMount() {
        const cookiesAccepted = LocalStorageService.getCookiesAccepted();
        const settings = {
            authority: HttpService.getConfig().identityServerUrl,
            client_id: HttpService.getConfig().identityServerClientId,
            redirect_uri: `${window.location.origin}/login-redirect.html?cookiesAccepted=${cookiesAccepted}`,
            post_logout_redirect_uri: `${window.location.origin}/logout-redirect.html`,
            silent_redirect_uri: `${window.location.origin}/silent-renew.html`,
            response_type: 'id_token token',
            scope: 'device_manager_api openid profile offline_access',
            automaticSilentRenew: true,
            accessTokenExpiringNotificationTime: 4,
            filterProtocolClaims: false,
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            loadUserInfo: true
        };
        this.userManager = new UserManager(settings);
        this.userManager.getUser().then(user => {
            HttpService.setAccessToken(user);
            this.setState({ user, loaded: true });
        });
        this.userManager.events.addUserLoaded(args => {
            this.userManager.getUser().then(user => {
                HttpService.setAccessToken(user);
                this.setState({ user });
            });
        });
    }

    render() {
        const { loaded, user, preSetFilter, setFilters } = this.state;

        return (
            <React.Fragment>
                {loaded && (
                    <AuthContext.Provider
                        value={{
                            state: {
                                user,
                                preSetFilter,
                                setFilters
                            },
                            actions: {
                                isLoggedIn: () => this.isLoggedIn(),
                                login: () => this.login(),
                                logout: () => this.logout(),
                                changePassword: () => this.changePassword(),
                                hasDeviceManagerRole: () => this.hasDeviceManagerRole(),
                                hasFirmwareManagerRole: () => this.hasFirmwareManagerRole(),
                                hasSystemAdminRole: () => this.hasSystemAdminRole(),
                                hasCompanyAdminRole: () => this.hasCompanyAdminRole(),
                                hasInventoryRole: () => this.hasInventoryRole(),
                                hasConfigurationRole: () => this.hasConfigurationRole(),
                                hasCommandsManagerRole: () => this.hasCommandsManagerRole(),
                                hasSimCardManagerRole: () => this.hasSimCardManagerRole(),
                                hasAccessRole: () => this.hasAccessRole()
                            }
                        }}
                    >
                        {this.props.children}
                    </AuthContext.Provider>
                )}
            </React.Fragment>
        );
    }
}

export const AuthConsumer = AuthContext.Consumer;
