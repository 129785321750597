import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Chart from 'chart.js';

import { Modal } from '../../components';
import { usePortal } from '../../hooks';
import { HttpService, UtilityService } from '../../services';
import colors from '../../styles/variables/_colours.scss';

export const BatteryUsageModal = ({ id, onClose }) => {
    const [data, setData] = useState(null);

    const graphRef = React.createRef();
    const portal = usePortal('modal');

    const predictedDateBeforeExpiryDate = data => {
        if (data.predictedExpiryDate && data.warrantyExpiryDate) {
            const predictedDate = new Date(data.predictedExpiryDate);
            const expiryDate = new Date(data.warrantyExpiryDate);
            return predictedDate < expiryDate;
        }

        return false;
    };

    const dailyUsageGreaterThanAverageUsage = data => {
        if (data.averageHoursDriven && data.allowedHoursPerDay) {
            return UtilityService.formatNumber(data.averageHoursDriven) > UtilityService.formatNumber(data.allowedHoursPerDay);
        }

        return false;
    };

    const getChartConfig = data => {
        if (!data) {
            return {};
        }

        const expectedHours = data.totalExpectedHours;

        // X-axis labesl
        const dateArray = () => {
            const arr = [];
            const startDate = new Date(data.warrantyStartDate);
            const endDate = new Date(data.warrantyExpiryDate);

            while (startDate <= endDate) {
                arr.push(new Date(startDate).toLocaleDateString());
                startDate.setMonth(startDate.getMonth() + 1);
            }
            return arr;
        };
        const originalData = () => {
            const data = [expectedHours];
            let currentPoint = expectedHours;
            while (currentPoint > 0) {
                currentPoint -= Math.floor(expectedHours / 12);
                data.push(currentPoint);
            }
            return data;
        };

        const estimatedData = () =>
            data.journeyDurations.map(j => ({ x: new Date(j.dateDriven).toLocaleDateString(), y: j.hoursDuration }));

        const config = {
            type: 'line',
            data: {
                labels: dateArray(),
                datasets: [
                    {
                        label: 'Predicted usage',
                        fill: false,
                        backgroundColor: colors.red,
                        borderColor: colors.red,
                        data: originalData()
                    },
                    {
                        label: 'Actual battery usage to date',
                        backgroundColor: colors.successGreen,
                        borderColor: colors.successGreen,
                        data: estimatedData()
                    }
                ]
            },
            options: {
                responsive: true,
                title: {
                    display: true,
                    text: 'Battery expected expiry.'
                },
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                scales: {
                    xAxes: [
                        {
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Months'
                            }
                        }
                    ],
                    yAxes: [
                        {
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Hours'
                            },
                            ticks: {
                                min: 0,
                                max: expectedHours,
                                stepSize: 40
                            }
                        }
                    ]
                }
            }
        };

        return config;
    };

    useEffect(() => {
        const elementRef = graphRef.current.getContext('2d');
        const chartConfig = getChartConfig(data);
        new Chart(elementRef, chartConfig);
    }, [data]);

    useEffect(() => {
        HttpService.get(`/Device/GetBatteryPrdictionData?id=${id}`).then(response => {
            setData(response.data);
        });
    }, []);

    return ReactDOM.createPortal(
        <Modal title="BATTERY USAGE" onCancel={onClose} cancelText="Close" sizeClass="mw-100 w-75">
            <div className="row">
                {data && (
                    <div className="col-md-4">
                        <dl className="row">
                            <dt className="col-sm-6">Drive time hours to date:</dt>
                            <dd className="col-sm-6">{UtilityService.formatNumber(data.hoursDriven) || '--'}</dd>
                            <dt className="col-sm-6">Number of days:</dt>
                            <dd className="col-sm-6">{UtilityService.formatNumber(data.daysDriven) || '--'}</dd>
                            <dt className="col-sm-6">Average daily use:</dt>
                            <dd className={`col-sm-6 ${dailyUsageGreaterThanAverageUsage(data) ? 'clr-red' : null}`}>
                                {UtilityService.formatNumber(data.averageHoursDriven) || '--'}
                            </dd>
                            <dt className="col-sm-6">Allowed daily use:</dt>
                            <dd className="col-sm-6">{UtilityService.formatNumber(data.allowedHoursPerDay) || '--'}</dd>
                            <dt className="col-sm-6">Warranty expiry date:</dt>
                            <dd className="col-sm-6">{UtilityService.localDateFormat(data.warrantyExpiryDate) || '--'}</dd>
                            <dt className="col-sm-6">Predicted expiry date:</dt>
                            <dd className={`col-sm-6 ${predictedDateBeforeExpiryDate(data) ? 'clr-red' : null}`}>
                                {UtilityService.localDateFormat(data.predictedExpiryDate) || '--'}
                            </dd>
                        </dl>
                    </div>
                )}
                <div className="col-md-8">
                    <canvas ref={graphRef} id="canvas" />
                </div>
            </div>
        </Modal>,
        portal
    );
};
