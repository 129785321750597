// Given a flat list of items it builds the tree hierarchy.
const buildTreeHierarchy = list => {
    let map = {},
        node,
        roots = [],
        i;
    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
    }
    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentId !== null && !!map[node.parentId]) {
            // if you have dangling branches check that map[node.parentId] exists
            list[map[node.parentId]]['children'].push(node);
        } else {
            roots.push(node);
        }
    }
    return roots;
};

const isValidJson = text => {
    if (typeof text !== 'string') {
        return false;
    }
    try {
        JSON.parse(text);
        return true;
    } catch (error) {
        return false;
    }
};

const objectToArray = obj => Object.keys(obj).map(o => ({ key: o, value: obj[o] }));

const formatNumber = num => {
    if (!num || isNaN(num) || num <= 0) {
        return null;
    }

    return Math.round(num * 100) / 100;
};

const localDateFormat = date => {
    if (!date) {
        return null;
    }

    if (typeof date === 'string') {
        date = new Date(date);
        if (date.toString() === 'Invalid Date') {
            return date;
        }
    }

    const dateOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    };

    const timeOptions = {
        hour: 'numeric',
        minute: 'numeric'
    };

    const formattedDate = date.toLocaleDateString('en-GB', dateOptions);
    const formattedTime = date.toLocaleTimeString('en-GB', timeOptions);
    const formattedDateTime = formattedDate + ' ' + formattedTime;
    return formattedDateTime;
};

const parseQueryString = queryString => {
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
};

const getFilterDropdownOptions = filterColumn => {
    if (filterColumn.filterListKey && filterColumn.filterListValue) {
        return filterColumn.filterList.map(listItem => ({
            value: listItem[filterColumn.filterListKey],
            text: listItem[filterColumn.filterListValue]
        }));
    }

    return filterColumn.filterList.map(listItem => ({
        value: listItem,
        text: listItem
    }));
};

const getRangeKeys = columnsName => {
    return {
        startKey: `${columnsName}Start`,
        endKey: `${columnsName}End`
    };
};

const secondsToHumanise = seconds => {
    let numyears = Math.floor(seconds / 31536000);
    let numdays = Math.floor((seconds % 31536000) / 86400);
    let numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    let numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    let numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;
    return `${numyears + ' years '} ${numdays + ' days '} ${numhours + ' hours '} ${numminutes + ' minutes '} ${numseconds + ' seconds'}`;
};

const UtilityService = {
    buildHierarchy: buildTreeHierarchy,
    isValidJson,
    objectToArray,
    formatNumber,
    localDateFormat,
    getFilterDropdownOptions,
    parseQueryString,
    getRangeKeys,
    secondsToHumanise
};

export { UtilityService };
