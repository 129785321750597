import React from 'react';

export const NotAuthorised = () => {
    return (
        <div>
            <h2>You are not authorised to access Device Manager</h2>
            <br />
            <h2>Please request access from your system administrator</h2>
            <h2>Or contact info@agmtechnologies.co.uk</h2>
        </div>
    );
};
