import React, { Component } from 'react';

export const BoxFilter = {
    All: 0,
    UnRegisterd: 1
};

export class BoxTypes extends Component {
    state = {
        boxTypes: [{ key: BoxFilter.All, value: 'All' }, { key: BoxFilter.UnRegisterd, value: 'Un-Registered' }]
    };

    render() {
        const { onChange } = this.props;
        const { boxTypes } = this.state;
        return (
            <>
                <label htmlFor="inlineFormInput">BOX TYPES</label>
                <select className="form-control clr-primary brd-primary" onChange={event => onChange(+event.target.value)}>
                    {boxTypes.map(boxType => (
                        <option key={`BoxType${boxType.key}`} value={boxType.key}>
                            {boxType.value}
                        </option>
                    ))}
                </select>
            </>
        );
    }
}
