import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ExpandTreeIcon from './ExpandTreeIcon';
import './TreeNode.scss';

export class TreeNode extends Component {
    constructor(props) {
        super(props);
        this.state = { collapsed: false };
    }

    onClick = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    };

    render() {
        let subtree = null;
        const { data, nodeClick } = this.props;
        const { collapsed } = this.state;
        if (data.children) {
            subtree = data.children.map((child, index) => {
                return <TreeNode key={index} data={child} nodeClick={nodeClick} />;
            });
        }

        var containerClassName = 'tree-node-children';
        if (this.state.collapsed) {
            containerClassName += ' tree-node-children-collapsed';
        }

        if (subtree) {
            const displayIcon = subtree && subtree.length > 0;
            return (
                <div className="tree-node">
                    <ExpandTreeIcon onClick={this.onClick} collapsed={collapsed} displayIcon={displayIcon} /> &nbsp;
                    <Link to="#" onClick={() => nodeClick(data)} data-id={data.id}>
                        {data.name}
                    </Link>
                    <div className={containerClassName}>{subtree}</div>
                </div>
            );
        } else {
            return (
                <div className="tree-node-leaf">
                    <Link to="#" onClick={() => nodeClick(data)} data-id={data.id}>
                        {data.name}
                    </Link>
                </div>
            );
        }
    }
}
