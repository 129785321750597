import React from 'react';

export const Pagination = ({ pageDetail, changePage }) => {
    const changePageNumber = pageNumber => changePage(pageNumber);

    const moveForwardOne = () => changePageNumber(pageDetail.pageNumber + 1);
    const moveBackOne = () => changePageNumber(pageDetail.pageNumber - 1);
    const moveToFirst = () => changePageNumber(1);
    const moveToLast = () => changePageNumber(pageDetail.numberOfPages);
    const moveForwardTwo = () => changePageNumber(pageDetail.pageNumber + 2);
    const moveBackTwo = () => changePageNumber(pageDetail.pageNumber - 2);

    const { pageNumber, numberOfPages } = pageDetail;
    return (
        <nav className="nav-pagination" aria-label="Device table pagination">
            <ul className="pagination">
                {/* If page is not first page, previous link is active */}
                <li className={`page-item${pageNumber === 1 ? ' disabled' : ''}`}>
                    <span onClick={moveBackOne} className="page-link" tabIndex="-1">
                        Previous
                    </span>
                </li>
                {/* If is last page, show the first numbered link, divider, and the prev two numbered links */}
                {numberOfPages > 3 && pageNumber === numberOfPages && (
                    <>
                        <li className="page-item">
                            <span onClick={moveToFirst} className="page-link">
                                1
                            </span>
                        </li>
                        <li className="page-item disabled">
                            <span className="page-link">...</span>
                        </li>
                    </>
                )}
                {numberOfPages > 2 && pageNumber === numberOfPages && (
                    <li className="page-item">
                        <span onClick={moveBackTwo} className="page-link">
                            {pageNumber - 2}
                        </span>
                    </li>
                )}
                {/* If there are more than four pages, and the current page after the second, and is not the last, show first numbered link */}
                {numberOfPages > 4 && pageNumber > 2 && pageNumber < numberOfPages && (
                    <>
                        <li className="page-item">
                            <span onClick={moveToFirst} className="page-link">
                                1
                            </span>
                        </li>
                        <li className="page-item disabled">
                            <span className="page-link">...</span>
                        </li>
                    </>
                )}

                {/* If there is more than one page, and the current page number is greater than one, show prev page number link */}
                {numberOfPages > 1 && pageNumber > 1 && (
                    <li className="page-item">
                        <span onClick={moveBackOne} className="page-link">
                            {pageNumber - 1}
                        </span>
                    </li>
                )}
                {/* Current page number link */}
                {numberOfPages > 0 && (
                    <li className="page-item active">
                        <span className="page-link">{pageNumber}</span>
                    </li>
                )}
                {/* Next page number link */}
                {numberOfPages > 0 && numberOfPages !== pageNumber && (
                    <li className="page-item">
                        <span onClick={moveForwardOne} className="page-link">
                            {pageNumber + 1}
                        </span>
                    </li>
                )}
                {/* If there are more than two pages, and current page is first page, show third page number link */}
                {numberOfPages > 2 && pageNumber === 1 && (
                    <li className="page-item">
                        <span onClick={moveForwardTwo} className="page-link">
                            {pageNumber + 2}
                        </span>
                    </li>
                )}
                {/* If there are more than three pages, show a divider and the last page number link */}
                {numberOfPages > 3 && pageNumber < numberOfPages-1 && (
                    <>
                        <li className="page-item disabled">
                            <span className="page-link">of</span>
                        </li>
                        <li className="page-item">
                            <span onClick={moveToLast} className="page-link">
                                {numberOfPages}
                            </span>
                        </li>
                    </>
                )}
                {/* If page is not the last page, next link is active */}
                <li className={`page-item${numberOfPages === 0 || numberOfPages === pageNumber ? ' disabled' : ''}`}>
                    <span onClick={moveForwardOne} className="page-link">
                        Next
                    </span>
                </li>
            </ul>
        </nav>
    );
};
