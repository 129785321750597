import React from 'react';
import { Link } from 'react-router-dom';
import { AppConsumer, AuthConsumer } from '../contexts';

export class Nav extends React.Component {
    state = {};
    buttonRef = React.createRef();

    rolescheck = authContext => {
        const { hasCompanyAdminRole } = authContext.actions;
        const { hasSystemAdminRole } = authContext.actions;
        var hasCompanyRole = hasCompanyAdminRole() || hasSystemAdminRole();
        return [
            {
                manageCompanyHide: !hasCompanyRole,
                systemAdminHide: !hasSystemAdminRole()
            }
        ];
    };

    render() {
        return (
            <nav className="navbar navbar-light">
                <h1 className="nav-title">IC DEVICE MANAGER</h1>

                <div className="navbar-actions">
                    <AuthConsumer>
                        {context => {
                            const { user } = context.state;
                            const { isLoggedIn, login, logout } = context.actions;

                            return (
                                <div>
                                    {this.rolescheck(context).map(div => (
                                        <React.Fragment>
                                            {isLoggedIn() ? (
                                                <div className="dropdown">
                                                    <button
                                                        className="btn dropdown-toggle"
                                                        type="button"
                                                        id="dropdownMenuButton"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <i className="fal fa-user" />
                                                        {user.profile.name}
                                                    </button>
                                                    <AppConsumer>
                                                        {context => {
                                                            const { config } = context.state;

                                                            return (
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                    <a
                                                                        className="dropdown-item"
                                                                        href={`${config.identityServerUrl}/Manage`}
                                                                    >
                                                                        <i className="fal fa-user-circle" />
                                                                        My account
                                                                    </a>

                                                                    <a
                                                                        className="dropdown-item"
                                                                        href={`${config.identityServerUrl}/UserAdmin`}
                                                                        hidden={div.systemAdminHide}
                                                                    >
                                                                        <i className="fal fa-users" />
                                                                        Manage users
                                                                    </a>

                                                                    <a
                                                                        className="dropdown-item"
                                                                        href={`${config.identityServerUrl}/Company`}
                                                                        hidden={div.manageCompanyHide}
                                                                    >
                                                                        <i className="fal fa-building" />
                                                                        Manage companies
                                                                    </a>

                                                                    <div hidden={div.systemAdminHide}>
                                                                        <Link className="dropdown-item" to="/GroupManagement">
                                                                            <i className="fal fa-users-cog" />
                                                                            Manage groups
                                                                        </Link>
                                                                    </div>

                                                                    <div className="dropdown-divider" />
                                                                    <Link
                                                                        className="dropdown-item"
                                                                        to="#"
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            logout();
                                                                        }}
                                                                    >
                                                                        <i className="fal fa-sign-out" />
                                                                        Logout
                                                                    </Link>
                                                                </div>
                                                            );
                                                        }}
                                                    </AppConsumer>
                                                </div>
                                            ) : (
                                                <button className="btn" type="button" id="loginMenuButton" onClick={login}>
                                                    <i className="fal fa-sign-in-alt" /> Login
                                                </button>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            );
                        }}
                    </AuthConsumer>
                </div>
            </nav>
        );
    }
}
