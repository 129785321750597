import React, { useState, useEffect } from 'react';

import { TableColumnType, TableFilterType, DynamicTable } from '../../components';
import { HttpService, UtilityService } from '../../services';

export const Audits = () => {
    const [auditTypes, setAuditTypes] = useState([]);
    const columns = [
        {
            name: 'raisedOn',
            text: 'Event Date',
            dataType: TableColumnType.DateTime
        },
        {
            name: 'name',
            text: 'Audit type',
            dataType: TableColumnType.Text,
            filterType: TableFilterType.List,
            filterList: auditTypes,
            filterListKey: 'key',
            filterListValue: 'value'
        },
        {
            name: 'description',
            text: 'Description',
            dataType: TableColumnType.Text
        },
        {
            name: 'triggeredBy',
            text: 'Triggered By',
            dataType: TableColumnType.Text,
            filterType: TableFilterType.Text
        },
        {
            name: 'raisedOn',
            text: 'Event Date',
            dataType: TableColumnType.DateTime,
            filterType: TableFilterType.DateRange,
            filterOnly: true
        }
    ];

    const tableText = {
        perPageLabel: 'Audits per page',
        totalCountLabel: 'Total Audits'
    };
    useEffect(() => {
        HttpService.get('/Audit/GetAuditTypes').then(response => setAuditTypes(UtilityService.objectToArray(response.data)));
    }, []);

    const formatHttpError = httpError => {
        if (httpError && httpError.response.data) {
            if (httpError.response.data['Filter.ToDate']) {
                return 'To date should be greater than from date.';
            }
        }
    };

    return (
        <DynamicTable
            tableColumns={columns}
            apiUrl={'/Audit/GetAudits'}
            filterButtonText="Filter Audits"
            tableText={tableText}
            formatHttpError={formatHttpError}
            defaultOrderColumn={4}
        />
    );
};
