import React from 'react';

export const EditableList = ({ columns, data, onEdit, onDelete }) => {
    return (
        <ul className="list-group">
            {
                <>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        {columns.map((column, index) => (
                            <div key={index} className="col-4 font-weight-bold">
                                {column.displayName}
                            </div>
                        ))}
                    </li>
                    {data &&
                        data.map((item, index) => (
                            <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                {item.rowContent.map((content, i) => (
                                    <div key={i} className="col-4">
                                        {content}
                                    </div>
                                ))}
                                <div className="btn-group btn-group-sm col-4">
                                    <button className="btn btn-error" onClick={() => onDelete(item.row)}>
                                        DELETE
                                    </button>
                                    &nbsp;
                                    <button className="btn btn-success" onClick={() => onEdit(item.row)}>
                                        EDIT
                                    </button>
                                </div>
                            </li>
                        ))}
                </>
            }
        </ul>
    );
};
