import React, { Component } from 'react';

import { TreeNode } from '../../components';
import { HttpService, UtilityService } from '../../services';

export class CustomerHierarchy extends Component {
    state = {
        customersHierarchy: []
    };

    componentDidMount() {
        this.getCustomers();
    }

    getCustomers = () => {
        HttpService.get('/Customer/GetAllChildCustomers').then(response => {
            this.setState({ customersHierarchy: UtilityService.buildHierarchy(response.data) });
        });
    };

    onCustomerClick = customer => {
        const { history } = this.props;
        history.push(`/StockSummary/${customer.id}`);
    };

    render() {
        const { customersHierarchy } = this.state;
        return (
            <div className="dashboard">
                <h2>CUSTOMER HIERARCHY</h2>
                {customersHierarchy.map((child, index) => {
                    return <TreeNode key={index} data={child} nodeClick={this.onCustomerClick} />;
                })}
            </div>
        );
    }
}
