import React, { Component } from 'react';
import { FormatService } from '../../../services';

export class StaticTableRow extends Component {
    state = {};

    rowRef = React.createRef();

    render() {
        const { data, columns, onSelect, allowSelection } = this.props;
        return (
            <>
                <tr className={onSelect && allowSelection && 'pointer'} ref={this.rowRef}>
                    {allowSelection && (
                        <td>
                            <div className="flex-center">
                                {allowSelection(data) && (
                                    <label className="checkbox-container">
                                        <input type="checkbox" checked={!!data.selected} onChange={() => onSelect(data.id)} />
                                        <span className="checkmark" />
                                    </label>
                                )}
                            </div>
                        </td>
                    )}
                    {columns &&
                        columns.map((column, index) => <td key={`${column.name}${index}`}>{FormatService.formatResult(data, column)}</td>)}
                </tr>
            </>
        );
    }
}
