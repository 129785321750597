import React from 'react';
import { HttpService } from '../../services';
import { TableColumnType, TableFilterType, DynamicTable } from '../../components';
export class DeviceMetaDataHistoryModal extends React.Component {
    state = {
        metaDataHistoryKeys: [],
        tableText: {
            perPageLabel: 'Metadata Histories per page',
            totalCountLabel: 'Total Metadata Histories'
        }
    };

    componentDidMount() {
        HttpService.get(`/Device/GetDistinctMetaDataHistoryKeys?idOfDevice=${this.props.idOfDevice}`).then(response =>
            this.setState({
                metaDataHistoryKeys: response.data
            })
        );
    }

    createColumns() {
        var tableColumns = [
            {
                name: 'dateTimeDataCollectedFromDevice',
                text: 'DATE & TIME COLLECTED',
                dataType: TableColumnType.DateTime,
                filterType: TableFilterType.DateRange
            },
            {
                name: 'key',
                text: 'KEY',
                dataType: TableColumnType.Text,
                filterType: TableFilterType.List,
                filterList: this.state.metaDataHistoryKeys.map(x => x)
            },
            {
                name: 'value',
                text: 'VALUE',
                dataType: TableColumnType.Text,
                filterType: TableFilterType.Text
            }
        ];
        return tableColumns;
    }

    render() {
        const tableColumns = this.createColumns();
        return (
            <>
                <div className="modal-backdrop fade in show" />
                <div className="modal fade in show">
                    <div className="modal-dialog device-metadatahistory">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">DEVICE METADATA HISTORY</h4>
                                <button onClick={this.props.onClose} className="close" data-dismiss="modal" aria-label="Close">
                                    <i className="fal fa-times" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <DynamicTable
                                    tableColumns={tableColumns}
                                    apiUrl={'/Device/GetMetaDataHistory'}
                                    deviceId={this.props.idOfDevice}
                                    defaultOrderColumn={0}
                                    filterButtonText="Filter Metadata History"
                                    tableText={this.state.tableText}
                                />
                            </div>
                            <div className="modal-footer">
                                <div className="modal-btn-container">
                                    <button onClick={this.props.onClose} className="btn btn-error">
                                        CLOSE &nbsp; <i className="fal fa-times" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
