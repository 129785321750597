import React from 'react';
import Select from 'react-select';

export const MultiSelectDropdown = ({
    label,
    inputValues = [],
    dropDownValues = [],
    onChange,
    keyIdentifier = 'id',
    valueIdentifier = 'value'
}) => {
    const handleGroupChange = (remainingOptions, meta) => {
        switch (meta.action) {
            case 'clear':
                const resetGroups = [...dropDownValues, ...inputValues];
                onChange([], resetGroups);
                break;
            case 'remove-value':
                const removedGroup = meta.removedValue;
                onChange(inputValues.filter(g => g.id !== removedGroup.id), [...dropDownValues, removedGroup]);
                break;
            case 'select-option':
                const addedGroup = meta.option;
                inputValues.push(addedGroup);
                onChange(inputValues, dropDownValues.filter(g => g.id !== addedGroup.id));
                break;
            default:
                break;
        }
    };

    return (
        <div className="form-group">
            <label>{label}</label>
            <Select
                value={inputValues}
                options={dropDownValues}
                isMulti
                getOptionValue={option => option[keyIdentifier]}
                getOptionLabel={option => option[valueIdentifier]}
                onChange={handleGroupChange}
            />
        </div>
    );
};
