import React, { useState, useEffect } from 'react';
import { HttpService } from '../services';

const CustomersDropdown = ({ onChange, hideCustomer }) => {
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        let mounted = true;
        HttpService.get('/Customer/GetAllCustomers').then(response => mounted && setCustomers(response.data));
        return () => {
            mounted = false;
        };
    }, []);

    return (
        <>
            <label htmlFor="inlineFormInput">CUSTOMER</label>
            <select className="form-control clr-primary brd-primary" onChange={event => onChange(event.target.value)}>
                <option value="">All</option>
                {customers
                    .filter(c => c.id !== (hideCustomer ? +hideCustomer : null))
                    .map(customer => (
                        <option key={`${customer.id}`} value={customer.id}>
                            {customer.customerName}
                        </option>
                    ))}
            </select>
        </>
    );
};

export { CustomersDropdown };
