import React from 'react';

export const Modal = props => {
    const { title, message, okText, cancelText, onClose, onCancel, children, sizeClass } = props;

    return (
        <React.Fragment>
            <div className="modal-backdrop fade in show" />
            <div className="modal fade in show">
                <div className={`modal-dialog ${sizeClass ? sizeClass : ''}`}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{title}</h4>
                            <button
                                onClick={onCancel}
                                className={`close ${onCancel ? 'visible' : 'invisible'}`}
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fal fa-times" />
                            </button>
                        </div>
                        <div className="modal-body">
                            {message && <p>{message}</p>}
                            {children && children}
                        </div>
                        <div className="modal-footer">
                            <div className="modal-btn-container">
                                {onCancel && (
                                    <button onClick={onCancel} className="btn btn-error">
                                        {cancelText || 'CANCEL'} &nbsp; <i className="fal fa-times" />
                                    </button>
                                )}
                                {onClose && (
                                    <button onClick={onClose} className="btn btn-success">
                                        {okText || 'OK'} &nbsp; <i className="fal fa-check" />
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
