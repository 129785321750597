const DefaultPageNumber = 1;
const DefaultPageSize = 10;

const TableColumnType = {
    Text: 1,
    Date: 2,
    DateTime: 3,
    YesNo: 4
};

const TableColumnOrder = {
    None: 1,
    Ascending: 2,
    Descending: 3
};

const TableFilterType = {
    Text: 1,
    List: 2,
    TextRange: 3,
    DateRange: 4,
    NumberRange: 5
};

export { DefaultPageNumber, DefaultPageSize, TableColumnOrder, TableColumnType, TableFilterType };
