import React from 'react';
import { TableColumnOrder, DefaultPageNumber, DefaultPageSize, Pagination, TableFilter, TablePageSize } from '../..';
import { HttpService, UtilityService } from '../../../services';
import { DynamicTableRow } from './DynamicTableRow';
import { AuthConsumer } from '../../../contexts';

export class DynamicTable extends React.Component {
    state = {
        selectAll: false,
        selectedIds: [],
        unSelectedIds: [],
        selectedDeviceTypes: [],
        selectedCount: 0,
        results: [],
        pageDetail: {
            pageNumber: DefaultPageNumber,
            pageSize: DefaultPageSize
        },
        orderDetail: {}
    };

    componentDidMount() {
        const initialState = this.props.initialState || {};
        const initialStateFilters = this.props.preSetFilter || initialState.filters || {};
        const tableState = { ...initialState, filters: { ...initialStateFilters, ...this.getQueryFilters() } };

        this.setState({ ...tableState }, () => {
            const { pageDetail, orderDetail, filters } = this.state;
            this.search(filters, pageDetail.pageNumber, pageDetail.pageSize, orderDetail.orderByColumn, orderDetail.orderByDirection);
        });
    }

    getQueryFilters = () => {
        let filters = {};
        if (window.location.search) {
            try {
                filters = JSON.parse(UtilityService.parseQueryString(window.location.search).filters);
            } catch (error) {
                console.error('Unable to parse filter from query string, Current querystring: ', window.location.search);
            }
        }
        return filters;
    };

    getTableColumns = () => this.props.tableColumns.filter(x => !x.filterOnly);

    getTableColumnCount = () => {
        const defaultTableColumnCount = 2;
        return this.getTableColumns().length + (this.anyAlerts() ? 1 : 0) + defaultTableColumnCount;
    };

    getSortedByClass = tableColumnName => {
        const classNames = ['pointer'];
        const { orderDetail } = this.state;
        if (orderDetail) {
            if (tableColumnName === orderDetail.orderByColumn) {
                classNames.push('sortBy');
                if (orderDetail.orderByDirection === TableColumnOrder.Descending) {
                    classNames.push('sortByDescending');
                }
            }
        }
        return classNames.join(' ');
    };

    isCurrentColumnOrdered = columnName => {
        const { orderDetail } = this.state;
        return columnName === orderDetail.orderByColumn;
    };

    anyAlerts = () => this.state.results && this.state.results.some(x => x.alerts && x.alerts.length);

    sortResults = tableColumnName => {
        const { pageDetail, orderDetail, filters } = this.state;
        let orderByDirection = TableColumnOrder.Ascending;

        if (tableColumnName === orderDetail.orderByColumn) {
            orderByDirection =
                orderDetail.orderByDirection === TableColumnOrder.Descending ? TableColumnOrder.Ascending : TableColumnOrder.Descending;
        }

        this.search(filters, pageDetail.pageNumber, pageDetail.pageSize, tableColumnName, orderByDirection);
    };

    selectAll = () => {
        const selectAll = !this.state.selectAll;
        const results = this.state.results.map(result => {
            result.selected = selectAll;
            return result;
        });
        const selectedCount = selectAll ? this.state.pageDetail.totalResults : 0;

        this.setState(
            { selectAll, results, selectedIds: [], unSelectedIds: [], selectedDeviceTypes: [], selectedCount },
            this.onSelectedChanged
        );
    };

    clearSelection = () => {
        const results = this.state.results.map(result => {
            result.selected = false;
            return result;
        });
        this.setState(
            { selectAll: false, results, selectedIds: [], unSelectedIds: [], selectedDeviceTypes: [], selectedCount: 0 },
            this.onSelectedChanged
        );
    };

    onChecked = (rowId, checked, deviceType) => {
        const { selectAll, selectedIds, unSelectedIds, selectedDeviceTypes } = this.state;
        const results = this.state.results.map(row => {
            if (row.id === rowId) {
                row.selected = !row.selected;
            }
            return row;
        });

        function getSelectIds() {
            if (!selectAll) {
                if (selectedIds) {
                    if (checked && selectedIds.indexOf(rowId) === -1) {
                        selectedIds.push(rowId);
                        return selectedIds;
                    }
                    return selectedIds.filter(x => x !== rowId);
                }
            }
            return [];
        }

        function getSelectedDeviceTypes() {
            if (!selectAll) {
                if (selectedDeviceTypes) {
                    if (deviceType) {
                        if (checked) {
                            selectedDeviceTypes.push(deviceType);
                            return selectedDeviceTypes;
                        } else {
                            var index = selectedIds.indexOf(rowId);
                            selectedDeviceTypes.splice(index, 1);
                            return selectedDeviceTypes;
                        }
                    }
                }
            }
            return [];
        }

        function getUnSelectIds() {
            if (selectAll) {
                if (checked) {
                    return unSelectedIds.filter(id => id !== rowId);
                } else {
                    if (rowId) {
                        unSelectedIds.push(rowId);
                    }
                    return unSelectedIds;
                }
            }
            return [];
        }

        const newSelectedIds = getSelectIds();
        const newSelectedDeviceTypes = getSelectedDeviceTypes();
        const newUnSelectedIds = getUnSelectIds();
        const selectedCount = this.getSelectedCount(newSelectedIds, newUnSelectedIds);

        this.setState(
            {
                results,
                selectedIds: newSelectedIds,
                unSelectedIds: newUnSelectedIds,
                selectedDeviceTypes: newSelectedDeviceTypes,
                selectedCount
            },
            this.onSelectedChanged
        );
    };

    getSelectedCount = (selectedIds, unSelectedIds) => {
        var count = this.state.selectAll
            ? this.state.pageDetail.totalResults - (unSelectedIds ? unSelectedIds.length : 0)
            : selectedIds && selectedIds.length;
        return count;
    };

    onFilterExpanded = filterExpanded => this.setState({ filterExpanded }, this.onSelectedChanged);

    changePage = pageNumber => {
        const { pageDetail, orderDetail, filters } = this.state;
        this.search(filters, pageNumber, pageDetail.pageSize, orderDetail.orderByColumn, orderDetail.orderByDirection);
    };

    changePerPage = newPageSize => {
        const { orderDetail, filters } = this.state;
        this.search(filters, 1, newPageSize, orderDetail.orderByColumn, orderDetail.orderByDirection);
    };

    changeFilters = filters => {
        this.setState({ filters }, () => {
            const { pageDetail, orderDetail, filters } = this.state;
            this.search(filters, 1, pageDetail.pageSize, orderDetail.orderByColumn, orderDetail.orderByDirection);
        });
    };

    onSelectedChanged = () => {
        if (this.props.onSelectedChanged) {
            const { results, ...rest } = this.state;
            const newState = { ...rest };
            this.props.onSelectedChanged(newState);
        }
    };

    search = (
        filters,
        pageNumber = DefaultPageNumber,
        pageSize = DefaultPageSize,
        orderByColumn = this.props.tableColumns[this.props.defaultOrderColumn].name,
        orderByDirection = TableColumnOrder.Descending
    ) => {
        const model = {
            deviceId: this.props.deviceId,
            filter: filters,
            paging: {
                pageNumber,
                pageSize
            },
            orderBy: {
                orderByColumn,
                orderByDirection
            }
        };
        HttpService.post(this.props.apiUrl, model).then(response => {
            let results = response.data.results.map(result => ({ ...result, selected: this.markSelect(result) }));
            if (this.props.onProcessResults) {
                results = this.props.onProcessResults(results);
            }
            this.setState(
                {
                    results,
                    pageDetail: response.data.pageDetail,
                    orderDetail: {
                        orderByColumn: model.orderBy.orderByColumn,
                        orderByDirection: model.orderBy.orderByDirection
                    }
                },
                this.onSelectedChanged
            );
        });
    };

    onExpandResult = (e, clickedRow) => {
        const el = e.target.tagName.toLowerCase();
        if (el !== 'span' && el !== 'label' && el !== 'input') {
            const tableResult = [...this.state.results];
            tableResult.forEach(row => {
                row.detailsViewOpen = row.id === clickedRow.id ? !row.detailsViewOpen : false;
            });
            this.setState({ results: tableResult });
        }
    };

    markSelect = row => {
        const { selectedIds, unSelectedIds, selectAll } = this.state;
        if (unSelectedIds && unSelectedIds.indexOf(row.id) > -1) {
            return false;
        }

        return (selectedIds && selectedIds.indexOf(row.id) > -1) || selectAll;
    };

    render() {
        const { filterExpanded, results, selectedCount, pageDetail, filters } = this.state;
        const {
            tableText,
            tableColumns,
            toggleRightPane,
            deviceCommandsHandler,
            changeCommandClickHandler,
            commandId,
            filterButtonText,
            rightPaneExpanded,
            toggleDeviceAuditModal,
            toggleDeviceSimExpireDateModal,
            toggleResponseModal,
            toggleDeviceMetaDataHistoryModal,
            toggleAnalyticsModal,
            enableSelection,
            RowDetailsTemplate
        } = this.props;

        var noRolesStyle = { textAlign: 'center' };

        return (
            <>
                {results && (
                    <div
                        className={`container-fluid table-container ${filterExpanded ? 'filter-expanded' : ''} ${
                            rightPaneExpanded ? 'right-pane-expanded' : ''
                        }`}
                    >
                        {filters && (
                            <TableFilter
                                tableColumns={tableColumns}
                                initialFilters={filters}
                                onFilterExpanded={this.onFilterExpanded}
                                onSearch={this.changeFilters}
                                rightPaneExpanded={rightPaneExpanded}
                                toggleRightPane={toggleRightPane}
                                rightPaneConfirmBtnFn={deviceCommandsHandler}
                                rightPaneCommandChangeFn={value => changeCommandClickHandler(value)}
                                commandId={commandId}
                                filterButtonText={filterButtonText || 'Filter'}
                                selectedCount={selectedCount}
                                pageDetail={pageDetail}
                                enableSelection={enableSelection}
                                clearSelection={this.clearSelection}
                            />
                        )}
                        <div className="row">
                            <div className="col">
                                <AuthConsumer>
                                    {context => {
                                        const {
                                            hasConfigurationRole,
                                            hasDeviceManagerRole,
                                            hasSystemAdminRole,
                                            hasFirmwareManagerRole,
                                            hasCommandsManagerRole
                                        } = context.actions;
                                        var hasEditDeviceRole =
                                            hasConfigurationRole() ||
                                            hasDeviceManagerRole() ||
                                            hasSystemAdminRole() ||
                                            hasFirmwareManagerRole() ||
                                            hasCommandsManagerRole();
                                        return (
                                            <div className="table-responsive">
                                                {hasEditDeviceRole && (noRolesStyle = null)}
                                                <table className="table" style={noRolesStyle}>
                                                    <thead>
                                                        <tr>
                                                            {enableSelection && (
                                                                <th className="flex-center">
                                                                    {/* <label className="checkbox-container">
                                                                        <input type="checkbox" checked={selectAll} onChange={this.selectAll} />
                                                                        <span className="checkmark" />
                                                                    </label> */}
                                                                </th>
                                                            )}
                                                            {this.anyAlerts() && <th />}
                                                            {this.getTableColumns() &&
                                                                this.getTableColumns().map(tableColumn => (
                                                                    <th
                                                                        key={tableColumn.name}
                                                                        className={this.getSortedByClass(tableColumn.name)}
                                                                        onClick={() => this.sortResults(tableColumn.name)}
                                                                    >
                                                                        {tableColumn.text} &nbsp;
                                                                        {this.isCurrentColumnOrdered(tableColumn.name) && (
                                                                            <i className="fal fa-chevron-down" />
                                                                        )}
                                                                    </th>
                                                                ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {results.map(result => (
                                                            <DynamicTableRow
                                                                key={`TableRow${result.id}`}
                                                                result={result}
                                                                colSpan={this.getTableColumnCount()}
                                                                tableColumns={this.getTableColumns()}
                                                                anyAlerts={this.anyAlerts()}
                                                                onChecked={this.onChecked}
                                                                expandResult={this.onExpandResult}
                                                                toggleDeviceAuditModal={toggleDeviceAuditModal}
                                                                toggleDeviceSimExpireDateModal={toggleDeviceSimExpireDateModal}
                                                                toggleResponseModal={toggleResponseModal}
                                                                toggleDeviceMetaDataHistoryModal={toggleDeviceMetaDataHistoryModal}
                                                                toggleAnalyticsModal={toggleAnalyticsModal}
                                                                enableSelection={enableSelection}
                                                                RowDetailsTemplate={RowDetailsTemplate}
                                                            />
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        );
                                    }}
                                </AuthConsumer>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {pageDetail && (
                                    <div className="table-controls">
                                        <TablePageSize
                                            pageDetail={pageDetail}
                                            changePerPage={this.changePerPage}
                                            perPageLabel={tableText.perPageLabel}
                                        />
                                        <div>
                                            {tableText.totalCountLabel}: {pageDetail.totalResults}
                                        </div>
                                        <Pagination pageDetail={{ ...pageDetail }} changePage={this.changePage} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
