import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import DatePicker from 'react-datepicker';

import { UtilityService } from '../../services';
import { displayDateRangeError, displayNumberRangeError } from './table.service';

export const DateRangeFilter = forwardRef(({ filterColumn, dateFilterRange }, ref) => {
    const initialiseFirstRun = useRef(true);
    const dateFirstRun = useRef(true);
    const dayFirstRun = useRef(true);
    const DateFilterTypes = {
        WithDate: 1,
        WithDays: 2
    };

    const { startKey: dateStartKey, endKey: dateEndKey } = UtilityService.getRangeKeys(
        filterColumn.name + (filterColumn.withDays ? 'Date' : '')
    );
    const { startKey: dayStartKey, endKey: dayEndKey } = UtilityService.getRangeKeys(filterColumn.name + 'Day');

    const { withDays } = filterColumn;
    const [currentType, setCurrentType] = useState(DateFilterTypes.WithDate);
    const [errors, setErrors] = useState({});
    const [dateValues, setDateValues] = useState({ [dateStartKey]: null, [dateEndKey]: null });
    const [dayValues, setDayValues] = useState({ [dayStartKey]: '', [dayEndKey]: '' });

    const radioOptions = [{ key: DateFilterTypes.WithDate, value: 'Date range' }, { key: DateFilterTypes.WithDays, value: 'Day range' }];

    const onDateChange = event => {
        event.persist();
        const { name, value } = event.target;
        setDateValues({ ...dateValues, [name]: value, doSearch: true });
    };

    const onDayChange = event => {
        event.persist();
        const { name, value } = event.target;
        setDayValues({ ...dayValues, [name]: value, doSearch: true });
    };

    const initialise = (doSearch = false) => {
        setErrors({});
        setDateValues({ [dateStartKey]: null, [dateEndKey]: null, doSearch });
        setDayValues({ [dayStartKey]: '', [dayEndKey]: '', doSearch });
    };

    // The component instance will be extended with whatever you return from the callback passed as the second argument
    useImperativeHandle(ref, () => ({
        initialise: () => initialise(false)
    }));

    useEffect(() => {
        if (dateFirstRun.current) {
            dateFirstRun.current = false;
            return;
        }
        const displayErrors = displayDateRangeError(dateValues[dateStartKey], dateValues[dateEndKey]);
        setErrors({ ...errors, [DateFilterTypes.WithDate]: displayErrors });
        if (dateValues.doSearch) {
            dateFilterRange({
                [dateStartKey]: dateValues[dateStartKey],
                [dateEndKey]: dateValues[dateEndKey],
                [dayStartKey]: null,
                [dayEndKey]: null,
                isValid: !displayErrors
            });
        }
        return () => {};
    }, [dateValues[dateStartKey], dateValues[dateEndKey]]);

    useEffect(() => {
        if (dayFirstRun.current) {
            dayFirstRun.current = false;
            return;
        }
        const displayErrors = displayNumberRangeError(dayValues[dayStartKey], dayValues[dayEndKey]);
        setErrors({ ...errors, [DateFilterTypes.WithDays]: displayErrors });
        if (dayValues.doSearch) {
            dateFilterRange({
                [dayStartKey]: dayValues[dayStartKey],
                [dayEndKey]: dayValues[dayEndKey],
                [dateStartKey]: null,
                [dateEndKey]: null,
                isValid: !displayErrors
            });
        }
        return () => {};
    }, [dayValues[dayStartKey], dayValues[dayEndKey]]);

    useEffect(() => {
        if (initialiseFirstRun.current) {
            initialiseFirstRun.current = false;
            return;
        }
        initialise(true);
    }, [currentType]);

    return (
        <>
            {withDays && (
                <div className="form-group">
                    {radioOptions.map(item => (
                        <div key={item.key} className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name={item.key}
                                id={item.key}
                                value={item.key}
                                checked={currentType === item.key}
                                onChange={e => setCurrentType(item.key)}
                            />
                            <label className="form-check-label" htmlFor={item.key}>
                                {item.value}
                            </label>
                        </div>
                    ))}
                </div>
            )}
            {currentType === DateFilterTypes.WithDate && (
                <>
                    <div className="filter-control">
                        <DatePicker
                            className="form-control"
                            id={dateStartKey}
                            name={dateStartKey}
                            placeholderText="dd/MM/yyyy HH:mm"
                            showMonthDropdown
                            showYearDropdown
                            strictParsing
                            selectsStart
                            showTimeSelect
                            timeFormat="HH:mm"
                            selected={dateValues[dateStartKey]}
                            onChange={value => {
                                let event = {
                                    target: {
                                        name: dateStartKey,
                                        value
                                    },
                                    persist: () => {}
                                };
                                onDateChange(event);
                            }}
                            dateFormat="dd/MM/yyyy HH:mm"
                        />
                    </div>
                    <div className="filter-control">
                        <DatePicker
                            className="form-control"
                            id={dateEndKey}
                            name={dateEndKey}
                            placeholderText="dd/MM/yyyy HH:mm"
                            showMonthDropdown
                            showYearDropdown
                            strictParsing
                            selectsEnd
                            showTimeSelect
                            timeFormat="HH:mm"
                            selected={dateValues[dateEndKey]}
                            onChange={value => {
                                let event = {
                                    target: {
                                        name: dateEndKey,
                                        value
                                    },
                                    persist: () => {}
                                };
                                onDateChange(event);
                            }}
                            dateFormat="dd/MM/yyyy HH:mm"
                        />

                        <div className={'range-validation ' + (errors[DateFilterTypes.WithDate] ? 'is-visible' : 'is-hidden')}>
                            Please populate both fields. End date should be greater than start date.
                        </div>
                    </div>
                </>
            )}
            {currentType === DateFilterTypes.WithDays && (
                <>
                    <div className="filter-control">
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Range start"
                            id={dayStartKey}
                            name={dayStartKey}
                            value={dayValues[dayStartKey]}
                            onChange={onDayChange}
                        />
                    </div>

                    <div className="filter-control">
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Range end"
                            id={dayEndKey}
                            name={dayEndKey}
                            value={dayValues[dayEndKey]}
                            onChange={onDayChange}
                        />
                        <div className={'range-validation ' + (errors[DateFilterTypes.WithDays] ? 'is-visible' : 'is-hidden')}>
                            Please populate both fields, otherwise this filter is ignored.
                        </div>
                    </div>
                </>
            )}
        </>
    );
});
