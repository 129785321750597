import React from 'react';
import { AuthConsumer } from '../../contexts';
import { HttpService } from '../../services';
import { MultiSelectDropdown } from '../../components';

export class EditDevicesModal extends React.Component {
    state = {
        customers: [],
        productLifecycleStages: [],
        groups: [],
        customerId: '',
        deviceType: '',
        productLifecycleStageId: '',
        groupIds: '',
        initialGroups: []
    };

    componentDidMount() {
        Promise.all([
            HttpService.get('/Customer/GetAllCustomers'),
            HttpService.get('/DeviceType/GetAllDeviceTypes'),
            HttpService.get('/ProductLifecycleStage/GetAlProductLifecycleStages'),
            HttpService.get('/Group')
        ]).then(([customersResponse, deviceTypesResponse, productLifecycleStagesResponse, groupsResponse]) => {
            this.setState({
                customers: customersResponse.data,
                deviceTypes: deviceTypesResponse.data,
                productLifecycleStages: productLifecycleStagesResponse.data,
                groups: groupsResponse.data
            });
        });
    }

    updateProperty = (property, value) => this.setState({ [property]: value });

    updateGroups = newGroups => {
        this.setState({ initialGroups: newGroups }, () => {
            this.updateProperty('groupIds', newGroups ? newGroups.map(g => g.id) : null);
        });
    };

    onUpdateDevices = () => {
        const { filters, selectedIds } = this.props.selected;
        HttpService.post('/Device/UpdateDevices', {
            filter: { ...filters, selectedIds },
            customerId: this.state.customerId,
            deviceType: this.state.deviceType,
            productLifecycleStageId: this.state.productLifecycleStageId,
            groupIds: this.state.groupIds
        }).then(response => this.props.onSuccess(response.data));
    };

    rolescheck = authContext => {
        const { hasSystemAdminRole } = authContext.actions;
        return [
            {
                hide: !hasSystemAdminRole()
            }
        ];
    };
    render() {
        const { selected, onClose } = this.props;
        const {
            customers,
            productLifecycleStages,
            groups,
            customerId,
            deviceType,
            productLifecycleStageId,
            groupIds,
            initialGroups
        } = this.state;

        return (
            <React.Fragment>
                <div className="modal-backdrop fade in show" />
                <div className="modal fade in show">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">EDIT DEVICES</h4>
                                <button onClick={onClose} className="close" data-dismiss="modal" aria-label="Close">
                                    <i className="fal fa-times" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    {`Select to update ${selected.selectedCount} 
                                    ${selected.selectedCount === 1 ? 'device' : 'devices'}.`}
                                </p>
                                <br />
                                <div className="form-group">
                                    <label htmlFor="customer">CUSTOMER</label>
                                    <select
                                        className="form-control"
                                        id="customer"
                                        name="customer"
                                        value={customerId}
                                        onChange={event => this.updateProperty('customerId', event.target.value)}
                                    >
                                        <option value="" disabled="disabled">
                                            SELECT
                                        </option>
                                        {customers.map(customer => (
                                            <option key={customer.id} value={customer.id}>
                                                {customer.customerName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <br />
                                <AuthConsumer>
                                    {context => {
                                        return (
                                            <div>
                                                {this.rolescheck(context).map(div => (
                                                    <div className="form-group" hidden={div.hide}>
                                                        <label htmlFor="product-lifecycle-stage">PRODUCT LIFECYCLE STAGE</label>
                                                        <select
                                                            className="form-control"
                                                            id="product-lifecycle-stage"
                                                            name="product-lifecycle-stage"
                                                            value={productLifecycleStageId}
                                                            onChange={
                                                                div.hide ? (
                                                                    <div />
                                                                ) : (
                                                                    event =>
                                                                        this.updateProperty('productLifecycleStageId', event.target.value)
                                                                )
                                                            }
                                                        >
                                                            <option value="" disabled="disabled">
                                                                SELECT
                                                            </option>
                                                            {productLifecycleStages.map(productLifecycleStage => (
                                                                <option key={productLifecycleStage.id} value={productLifecycleStage.id}>
                                                                    {productLifecycleStage.stageName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <br />
                                                    </div>
                                                ))}
                                            </div>
                                        );
                                    }}
                                </AuthConsumer>
                                <MultiSelectDropdown
                                    label="ASSIGN GROUPS"
                                    valueIdentifier="groupName"
                                    inputValues={initialGroups}
                                    dropDownValues={groups}
                                    onChange={newTextValues => this.updateGroups(newTextValues)}
                                />
                            </div>
                            <div className="modal-footer">
                                <div className="modal-btn-container">
                                    <button
                                        onClick={this.onUpdateDevices}
                                        disabled={!customerId && !deviceType && !productLifecycleStageId && !groupIds}
                                        className="btn btn-success"
                                    >
                                        UPDATE DEVICES &nbsp; <i className="fal fa-pencil" />
                                    </button>
                                    <button onClick={onClose} className="btn btn-error">
                                        CANCEL &nbsp; <i className="fal fa-times" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
