import React from 'react';
import { HttpService } from '../../services';

export class UpdateOctafishFirmwareModal extends React.Component {
    state = {
        applicationFirmwares: [],
        gpsFirmwares: [],
        gsmFirmwares: [],
        rampFirmwares: [],
        applicationFirmwareId: '',
        gpsFirmwareId: '',
        gsmFirmwareId: '',
        rampFirmwareId: ''
    };

    componentDidMount() {
        const { selected, devices } = this.props;
        var deviceTypesTmp = new Set();
        devices.forEach(result => {
            if (selected.selectedIds.includes(result.id)) {
                deviceTypesTmp.add(result.deviceType);
            }
        });
        let deviceTypes = [];
        deviceTypesTmp.forEach(result => {
            deviceTypes.push(result);
        });
        if (deviceTypes.length == 1) {
            HttpService.get('/Firmware/GetOctafishFirmwaresFilter?deviceType=' + deviceTypes[0]).then(response =>
                this.setState({
                    applicationFirmwares: response.data
                })
            );
        }
    }

    updateApplicationFirmwareId = firmwareId => {
        this.setState({
            applicationFirmwareId: firmwareId,
            gpsFirmwareId: '',
            gsmFirmwareId: '',
            rampFirmwareId: ''
        });
        for (const firmware of this.state.applicationFirmwares) {
            if (firmwareId == firmware.id) {
                this.setState({
                    gpsFirmwares: firmware.gpsFirmwareViewModel,
                    gsmFirmwares: firmware.gsmFirmwareViewModel,
                    rampFirmwares: firmware.rampFirmwareViewModel
                });
            }
        }
    };

    onUpdateFirmware = () => {
        const applicationFirmware = this.state.applicationFirmwares.find(x => String(x.id) === this.state.applicationFirmwareId);
        const gpsFirmware = this.state.gpsFirmwares.find(x => String(x.id) === this.state.gpsFirmwareId);
        const gsmFirmware = this.state.gsmFirmwares.find(x => String(x.id) === this.state.gsmFirmwareId);
        const rampFirmware = this.state.rampFirmwares.find(x => String(x.id) === this.state.rampFirmwareId);
        const { selectedIds } = this.props.selected;
        HttpService.post('/Firmware/UpdateOctafishFirmware', {
            filter: { selectedIds },
            applicationFirmware: applicationFirmware,
            gpsFirmware: gpsFirmware,
            gsmFirmware: gsmFirmware,
            rampFirmware: rampFirmware
        }).then(() => this.props.onSuccess(applicationFirmware && applicationFirmware.firmwareVersion));
    };

    render() {
        const { selected, selectedDeviceTypes, devices, onClose } = this.props;
        const {
            applicationFirmwares,
            gpsFirmwares,
            gsmFirmwares,
            rampFirmwares,
            applicationFirmwareId,
            gpsFirmwareId,
            gsmFirmwareId,
            rampFirmwareId
        } = this.state;

        var onlyOctafish = true;
        var deviceTypes = new Set(selectedDeviceTypes);
        devices.forEach(result => {
            var [firstDeviceType] = deviceTypes;
            if (deviceTypes.size > 1 || firstDeviceType != 'IC7.O') {
                onlyOctafish = false;
            }
        });
        if (!onlyOctafish) {
            return (
                <React.Fragment>
                    <div className="modal-backdrop fade in show" />
                    <div className="modal fade in show">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">UPDATE FIRMWARE</h4>
                                    <button onClick={onClose} className="close" data-dismiss="modal" aria-label="Close">
                                        <i className="fal fa-times" />
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>
                                        {`The selected devices contain non-Octafish devices. You can only select Octafish devices to be updated here.`}
                                    </p>
                                </div>
                                <div className="modal-footer">
                                    <div className="modal-btn-container">
                                        <button onClick={onClose} className="btn btn-error">
                                            CANCEL &nbsp; <i className="fal fa-times" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="modal-backdrop fade in show" />
                    <div className="modal fade in show">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">UPDATE FIRMWARE</h4>
                                    <button onClick={onClose} className="close" data-dismiss="modal" aria-label="Close">
                                        <i className="fal fa-times" />
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>
                                        {`Select firmware version to update ${selected.selectedCount} 
                                    ${selected.selectedCount === 1 ? 'device' : 'devices'}.`}
                                    </p>
                                    <div className="form-group">
                                        <label htmlFor="customer">APPLICATION FIRMWARE VERSION</label>
                                        <select
                                            className="form-control"
                                            id="firmware"
                                            name="firmware"
                                            value={applicationFirmwareId}
                                            onChange={event => this.updateApplicationFirmwareId(event.target.value)}
                                        >
                                            <option value="" disabled="disabled">
                                                SELECT
                                            </option>
                                            {applicationFirmwares.map(applicationFirmware => (
                                                <option key={applicationFirmware.id} value={applicationFirmware.id}>
                                                    {applicationFirmware.firmwareVersion}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group" hidden={!applicationFirmwareId}>
                                        <label htmlFor="customer">GPS FIRMWARE VERSION</label>
                                        <select
                                            className="form-control"
                                            id="firmware"
                                            name="firmware"
                                            value={gpsFirmwareId}
                                            onChange={event => this.setState({ gpsFirmwareId: event.target.value })}
                                        >
                                            <option value="" disabled="disabled">
                                                SELECT
                                            </option>
                                            {gpsFirmwares.map(gpsFirmware => (
                                                <option key={gpsFirmware.id} value={gpsFirmware.id}>
                                                    {gpsFirmware.firmwareVersion}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group" hidden={!applicationFirmwareId}>
                                        <label htmlFor="customer">GSM FIRMWARE VERSION</label>
                                        <select
                                            className="form-control"
                                            id="firmware"
                                            name="firmware"
                                            value={gsmFirmwareId}
                                            onChange={event => this.setState({ gsmFirmwareId: event.target.value })}
                                        >
                                            <option value="" disabled="disabled">
                                                SELECT
                                            </option>
                                            {gsmFirmwares.map(gsmFirmware => (
                                                <option key={gsmFirmware.id} value={gsmFirmware.id}>
                                                    {gsmFirmware.firmwareVersion}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group" hidden={!applicationFirmwareId}>
                                        <label htmlFor="customer">RAMP FIRMWARE VERSION</label>
                                        <select
                                            className="form-control"
                                            id="firmware"
                                            name="firmware"
                                            value={rampFirmwareId}
                                            onChange={event => this.setState({ rampFirmwareId: event.target.value })}
                                        >
                                            <option value="" disabled="disabled">
                                                SELECT
                                            </option>
                                            {rampFirmwares.map(rampFirmware => (
                                                <option key={rampFirmware.id} value={rampFirmware.id}>
                                                    {rampFirmware.firmwareVersion}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="modal-btn-container">
                                        <button
                                            onClick={this.onUpdateFirmware}
                                            disabled={!applicationFirmwareId}
                                            className="btn btn-success"
                                        >
                                            UPDATE FIRMWARE &nbsp; <i className="fal fa-cloud-upload" />
                                        </button>
                                        <button onClick={onClose} className="btn btn-error">
                                            CANCEL &nbsp; <i className="fal fa-times" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }
}
