import React, { useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { HttpService } from '../../services';
import { addDays, subDays } from 'date-fns';

export class DeviceAnalyticsModal extends React.Component {
    state = {
        chartDataVoltage: [],
        chartDataTemperature: [],
        chartDataOther: [],
        chartDataCellAnalytics: [],
        chartDataRSSIDistribution: [],
        chartDataPKT: [],
        chartDataVoltageSelectedDataTypes: ['Primary Voltage', 'Secondary Voltage', 'Secondary Battery', 'Solar Voltage'],
        chartDataTemperatureSelectedDataTypes: ['Primary Temperature', 'Secondary Temperature', 'IMU Temperature'],
        dateRange: [new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), new Date()] // Default to one week ago until today
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        const [startDate, endDate] = this.state.dateRange;

        if (!startDate || !endDate) {
            console.error('Start date or end date is undefined. Please select a valid date range.');
            return;
        }

        const startDateString = startDate.toISOString();
        const endDateString = endDate.toISOString();

        HttpService.get(`/Device/GetDeviceAnalytics?id=${this.props.idOfDevice}&startDate=${startDateString}&endDate=${endDateString}`)
            .then(response => {
                const { chartDataVoltage, chartDataTemperature, chartDataOther } = this.transformData(
                    response.data.octaPowerAnalyticsDatas
                );
                const { chartDataRSSIDistribution, chartDataPKT } = this.transformDataCellData(response.data.cellAnalyticsDatas);
                this.setState({
                    chartDataVoltage,
                    chartDataTemperature,
                    chartDataRSSIDistribution,
                    chartDataPKT,
                    chartDataOther,
                    chartDataVoltageSelectedDataTypes: ['Primary Voltage', 'Secondary Voltage', 'Secondary Battery', 'Solar Voltage'],
                    chartDataTemperatureSelectedDataTypes: ['Primary Temperature', 'Secondary Temperature', 'IMU Temperature']
                });
            })
            .catch(error => {
                console.error('Error fetching device analytics data:', error);
            });
    };

    transformData = data => {
        const convertEpochToDateTime = epoch => {
            const date = new Date(epoch * 1000);
            return date.toISOString(); // Returns date as 'YYYY-MM-DDTHH:mm:ss.sssZ'
        };

        const mapDataWithNullChecks = (data, yKey) =>
            data
                .map(item => ({
                    x: convertEpochToDateTime(item.ts),
                    y: item[yKey] !== null && item[yKey] !== undefined ? item[yKey] : null
                }))
                .filter(point => point.x !== null && point.y !== null);

        const groupAndCountRSSI = data => {
            const rssiCounts = {};

            data.forEach(item => {
                const rssiValue = item.rssi !== null && item.rssi !== undefined ? item.rssi : null;
                if (rssiValue !== null) {
                    rssiCounts[rssiValue] = (rssiCounts[rssiValue] || 0) + 1;
                }
            });

            return Object.keys(rssiCounts).map(key => ({
                x: key,
                y: rssiCounts[key]
            }));
        };

        const chartDataVoltage = [
            {
                id: 'Primary Voltage',
                color: 'hsl(206, 70%, 50%)',
                data: mapDataWithNullChecks(data, 'vbp')
            },
            {
                id: 'Secondary Voltage',
                color: 'hsl(104, 70%, 50%)',
                data: mapDataWithNullChecks(data, 'vbs')
            },
            {
                id: 'Secondary Battery',
                color: 'hsl(50, 70%, 50%)',
                data: mapDataWithNullChecks(data, 'vss')
            },
            {
                id: 'Solar Voltage',
                color: 'hsl(10, 70%, 50%)',
                data: mapDataWithNullChecks(data, 'vsl')
            }
        ];

        const chartDataTemperature = [
            {
                id: 'Primary Temperature',
                color: 'hsl(306, 70%, 50%)',
                data: mapDataWithNullChecks(data, 'tbp')
            },
            {
                id: 'Secondary Temperature',
                color: 'hsl(170, 70%, 50%)',
                data: mapDataWithNullChecks(data, 'tbs')
            },
            {
                id: 'IMU Temperature',
                color: 'hsl(220, 70%, 50%)',
                data: mapDataWithNullChecks(data, 'ti')
            }
        ];

        const chartDataOther = [
            {
                id: 'Journey Start Time',
                color: 'hsl(120, 70%, 50%)',
                data: mapDataWithNullChecks(data, 'jny')
            },
            {
                id: 'Journey Quiet Time',
                color: 'hsl(180, 70%, 50%)',
                data: mapDataWithNullChecks(data, 'jnyEndQT')
            },
            {
                id: 'Journey Length (Configured Quiet Time)',
                color: 'hsl(300, 70%, 50%)',
                data: mapDataWithNullChecks(data, 'jnyLenA')
            },
            {
                id: 'Journey Length (Optimized Quiet Time)',
                color: 'hsl(240, 70%, 50%)',
                data: mapDataWithNullChecks(data, 'jnyLenB')
            },
            {
                id: 'Lowest Voltage During Journey',
                color: 'hsl(60, 70%, 50%)',
                data: mapDataWithNullChecks(data, 'vLow')
            }
        ];

        return { chartDataVoltage, chartDataTemperature, chartDataOther };
    };

    transformDataCellData = data => {
        const convertEpochToDateTime = epoch => {
            const date = new Date(epoch * 1000);
            return date.toISOString(); // Returns date as 'YYYY-MM-DDTHH:mm:ss.sssZ'
        };

        const mapDataWithNullChecksAndGaps = data => {
            const result = [];
            let previousOperator = null;

            data.forEach(item => {
                const currentOperator = item.op || 'Unknown Operator';
                const point = {
                    x: convertEpochToDateTime(item.ts),
                    y: item.rssi !== null && item.rssi !== undefined ? item.rssi : null,
                    rat: item.rat,
                    op: item.op
                };

                if (previousOperator && currentOperator !== previousOperator) {
                    // Insert a null point to create a gap in the line chart
                    result.push({ x: point.x, y: null, rat: null, op: previousOperator });
                }

                result.push(point);
                previousOperator = currentOperator;
            });

            return result;
        };

        const processedData = mapDataWithNullChecksAndGaps(data);
        const groupedDataByOperator = processedData.reduce((acc, item) => {
            const operator = item.op || 'Unknown Operator';
            if (!acc[operator]) {
                acc[operator] = [];
            }
            acc[operator].push(item);
            return acc;
        }, {});

        const chartDataRSSIDistribution = Object.keys(groupedDataByOperator).map((operator, index) => ({
            id: `RSSI Distribution - ${operator}`,
            color: `hsl(${index * 40}, 70%, 50%)`, // Dynamically assign a color to each operator
            data: groupedDataByOperator[operator]
        }));

        // Generate PKT data (not grouped by operator)
        const chartDataPKT = [
            {
                id: 'Packet Count (high indicates problems)',
                color: 'hsl(200, 70%, 50%)',
                data: data
                    .map(item => ({
                        x: convertEpochToDateTime(item.ts),
                        y: item.pkt !== null && item.pkt !== undefined ? item.pkt : null
                    }))
                    .filter(point => point.x !== null && point.y !== null)
            }
        ];

        return { chartDataRSSIDistribution, chartDataPKT };
    };

    handleDateChange = dates => {
        if (dates === null) {
            this.setState({ dateRange: [null, null] }, this.fetchData);
        } else {
            this.setState({ dateRange: dates }, this.fetchData);
        }
    };

    handleQuickSelect = days => {
        const endDate = new Date();
        const startDate = subDays(endDate, days);
        this.setState({ dateRange: [startDate, endDate] }, this.fetchData);
    };

    renderChart = (data, title) => {
        if (!data || data.length === 0 || data.every(series => series.data.length === 0)) {
            return <p>No data available for {title}</p>;
        }

        return (
            <div style={{ height: '300px', marginBottom: '40px' }}>
                <h3>{title}</h3>
                <ResponsiveLine
                    data={data}
                    margin={{ top: 50, right: 30, bottom: 70, left: 60 }}
                    xScale={{ type: 'time', format: '%Y-%m-%dT%H:%M:%S.%LZ', precision: 'minute' }}
                    xFormat="time:%Y-%m-%d %H:%M"
                    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        format: '%m/%d %H:%M',
                        tickSize: 5,
                        tickPadding: 10,
                        tickRotation: -45,
                        legend: 'Journey Date and Time',
                        legendOffset: 60,
                        legendPosition: 'middle'
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Value',
                        legendOffset: -50,
                        legendPosition: 'middle'
                    }}
                    pointSize={5}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={1}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    isInteractive={true}
                    tooltip={({ point }) => {
                        const ratType = point.data.rat === 7 ? 'Cat-M1' : point.data.rat === 9 ? 'NB-IoT' : null;

                        return (
                            <div
                                style={{
                                    background: 'white',
                                    padding: '9px 12px',
                                    border: '1px solid #ccc'
                                }}
                            >
                                <strong>{point.serieId}</strong>
                                <br />
                                Date: {point.data.xFormatted}
                                <br />
                                Value: {point.data.yFormatted}
                                {ratType && (
                                    <>
                                        <br />
                                        RAT: {ratType}
                                    </>
                                )}
                            </div>
                        );
                    }}
                    legends={[
                        {
                            anchor: 'top-right',
                            direction: 'row',
                            justify: false,
                            translateX: -120,
                            translateY: -40,
                            itemsSpacing: 130,
                            itemDirection: 'left-to-right',
                            itemWidth: 100,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                />
            </div>
        );
    };

    renderDataTypeSelector = (selectedDataTypes, onChangeHandler) => {
        const dataTypes = [
            { label: 'Primary Voltage', value: 'Primary Voltage' },
            { label: 'Secondary Voltage', value: 'Secondary Voltage' },
            { label: 'Secondary Battery', value: 'Secondary Battery' },
            { label: 'Solar Voltage', value: 'Solar Voltage' },
            { label: 'Primary Temperature', value: 'Primary Temperature' },
            { label: 'Secondary Temperature', value: 'Secondary Temperature' },
            { label: 'IMU Temperature', value: 'IMU Temperature' },
            { label: 'Journey Start Time', value: 'Journey Start Time' },
            { label: 'Journey Quiet Time', value: 'Journey Quiet Time' },
            { label: 'Journey Length (Configured Quiet Time)', value: 'Journey Length (Configured Quiet Time)' },
            { label: 'Journey Length (Optimized Quiet Time)', value: 'Journey Length (Optimized Quiet Time)' },
            { label: 'Lowest Voltage During Journey', value: 'Lowest Voltage During Journey' }
        ];

        return (
            <div style={{ marginBottom: '20px' }}>
                <h3>Select Data Types:</h3>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '1px' }}>
                    {dataTypes.map((type, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '1px' }}>
                            <input
                                type="checkbox"
                                id={type.value}
                                value={type.value}
                                onChange={onChangeHandler}
                                checked={selectedDataTypes.includes(type.value)}
                                style={{ marginRight: '1px' }}
                            />
                            <label htmlFor={type.value} style={{ fontSize: '13px' }}>
                                {type.label}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    handleVoltageDataTypeSelection = event => {
        const { value } = event.target;
        this.setState(prevState => {
            const isSelected = prevState.chartDataVoltageSelectedDataTypes.includes(value);
            const chartDataVoltageSelectedDataTypes = isSelected
                ? prevState.chartDataVoltageSelectedDataTypes.filter(type => type !== value)
                : [...prevState.chartDataVoltageSelectedDataTypes, value];

            return { chartDataVoltageSelectedDataTypes };
        });
    };

    handleTemperatureDataTypeSelection = event => {
        const { value } = event.target;
        this.setState(prevState => {
            const isSelected = prevState.chartDataTemperatureSelectedDataTypes.includes(value);
            const chartDataTemperatureSelectedDataTypes = isSelected
                ? prevState.chartDataTemperatureSelectedDataTypes.filter(type => type !== value)
                : [...prevState.chartDataTemperatureSelectedDataTypes, value];

            return { chartDataTemperatureSelectedDataTypes };
        });
    };

    getFilteredVoltageData = () => {
        const { chartDataVoltage, chartDataTemperature, chartDataOther, chartDataVoltageSelectedDataTypes } = this.state;

        const allRelevantData = [...chartDataVoltage, ...chartDataTemperature, ...chartDataOther];

        return allRelevantData.filter(series => chartDataVoltageSelectedDataTypes.includes(series.id));
    };

    getFilteredTemperatureData = () => {
        const { chartDataVoltage, chartDataTemperature, chartDataOther, chartDataTemperatureSelectedDataTypes } = this.state;

        const allRelevantData = [
            ...chartDataVoltage,
            ...chartDataTemperature, // Include temperature data
            ...chartDataOther
        ];

        return allRelevantData.filter(series => chartDataTemperatureSelectedDataTypes.includes(series.id));
    };

    render() {
        const { chartDataRSSIDistribution, chartDataPKT } = this.state;

        return (
            <>
                <div className="modal-backdrop fade in show" />
                <div className="modal fade in show">
                    <div className="modal-dialog device-metadatahistory">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">DEVICE ANALYTICS</h4>
                                <button onClick={this.props.onClose} className="close" data-dismiss="modal" aria-label="Close">
                                    <i className="fal fa-times" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="datepicker-and-buttons-container">
                                    {/* Date Picker */}
                                    <div className="datepicker-container-horizontal">
                                        <label className="datepicker-label">Select Date Range: </label>
                                        <DatePicker
                                            className="custom-datepicker"
                                            selectsRange={true}
                                            startDate={this.state.dateRange[0]}
                                            endDate={this.state.dateRange[1]}
                                            onChange={this.handleDateChange}
                                            isClearable={true}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </div>

                                    {/* Quick Select Buttons */}
                                    <div className="quick-select-container">
                                        <label className="quick-select-label">Quick Select: </label>
                                        <button className="quick-select-button" onClick={() => this.handleQuickSelect(7)}>
                                            Last 7 days
                                        </button>
                                        <button className="quick-select-button" onClick={() => this.handleQuickSelect(30)}>
                                            Last 30 days
                                        </button>
                                        <button className="quick-select-button" onClick={() => this.handleQuickSelect(90)}>
                                            Last 90 days
                                        </button>
                                        <button className="quick-select-button" onClick={() => this.handleQuickSelect(365)}>
                                            Last 1 year
                                        </button>
                                    </div>
                                </div>
                                {this.renderDataTypeSelector(
                                    this.state.chartDataVoltageSelectedDataTypes,
                                    this.handleVoltageDataTypeSelection
                                )}
                                {this.renderChart(this.getFilteredVoltageData(), 'Voltage Data')}
                                {this.renderDataTypeSelector(
                                    this.state.chartDataTemperatureSelectedDataTypes,
                                    this.handleTemperatureDataTypeSelection
                                )}
                                {this.renderChart(this.getFilteredTemperatureData(), 'Temperature Data')}
                                {this.renderChart(chartDataRSSIDistribution, 'Cellular RSSI with Operator')}
                                {this.renderChart(chartDataPKT, 'Cellular PKT Distribution')}
                            </div>
                            <div className="modal-footer">
                                <div className="modal-btn-container">
                                    <button onClick={this.props.onClose} className="btn btn-error">
                                        CLOSE &nbsp; <i className="fal fa-times" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
