import React from 'react';
import { Link } from 'react-router-dom';

import { TableFilterType } from '../../components';
import { UtilityService } from '../../services';
import { RightPane } from '../RightPane';
import { FilterInfo } from './FilterInfo';
import { DateRangeFilter } from './DateRangeFilter';
import { displayTextRangeError, displayNumberRangeError } from './table.service';
export class TableFilter extends React.Component {
    constructor(props) {
        super(props);
        const dateFilterColumns = props.tableColumns.filter(c => c.filterType === TableFilterType.DateRange);
        dateFilterColumns.forEach(c => {
            this[c.name + 'Ref'] = React.createRef();
        });
    }

    filterTableColumnsReducer = (obj, filterColumn) => {
        let filter = { ...obj };

        if (filterColumn.filterType === TableFilterType.TextRange || filterColumn.filterType === TableFilterType.NumberRange) {
            const { startKey, endKey } = UtilityService.getRangeKeys(filterColumn.name);
            filter[startKey] = '';
            filter[endKey] = '';
        } else if (filterColumn.filterType === TableFilterType.DateRange) {
            if (filterColumn.withDays) {
                const { startKey: dateStartKey, endKey: dateEndKey } = UtilityService.getRangeKeys(filterColumn.name + 'Date');
                const { startKey: dayStartKey, endKey: dayEndKey } = UtilityService.getRangeKeys(filterColumn.name + 'Day');
                filter[dateStartKey] = '';
                filter[dateEndKey] = '';
                filter[dayStartKey] = '';
                filter[dayEndKey] = '';
            } else {
                const { startKey, endKey } = UtilityService.getRangeKeys(filterColumn.name);
                filter[startKey] = '';
                filter[endKey] = '';
            }
        } else {
            filter[filterColumn.name] = filterColumn.filterMultiple ? [] : '';
        }

        return filter;
    };

    getInitialFilterValues = (getDefaultOnly = false) => {
        const blankFilters = this.filterTableColumns.reduce(this.filterTableColumnsReducer, {});
        if (getDefaultOnly) {
            return blankFilters;
        }
        const { initialFilters } = this.props;
        if (initialFilters && Object.keys(initialFilters).length > 0) {
            const filters = { ...blankFilters, ...initialFilters };
            return filters;
        }
        return blankFilters;
    };
    getInitialFilterOpen = () => {
        const { initialFilters } = this.props;
        const filterOpen = {};
        if (initialFilters && Object.keys(initialFilters).length > 0) {
            Object.keys(initialFilters).forEach(item => (filterOpen[item] = !!initialFilters[item]));
        }
        return filterOpen;
    };

    state = {
        filterExpanded: false,
        filterOpen: this.getInitialFilterOpen(),
        filters: this.getInitialFilterValues(),
        filterErrors: {}
    };

    componentWillUnmount() {
        this.clearKeyUpSearchTimer();
    }

    get filterTableColumns() {
        return this.props.tableColumns.filter(filterColumn => filterColumn.filterType);
    }

    isAnyFilterActive = () => {
        return this.props.tableColumns.some(column => this.isFilterActive(column));
    };

    isFilterActive = filterColumn => {
        let filterValue;

        if (filterColumn.filterType === TableFilterType.TextRange || filterColumn.filterType === TableFilterType.NumberRange) {
            const { startKey, endKey } = UtilityService.getRangeKeys(filterColumn.name);
            const startValue = this.state.filters[startKey];
            const endValue = this.state.filters[endKey];
            return startValue || endValue;
        } else if (filterColumn.filterType === TableFilterType.DateRange) {
            if (filterColumn.withDays) {
                const { startKey: dateStartKey, endKey: dateEndKey } = UtilityService.getRangeKeys(filterColumn.name + 'Date');
                const { startKey: dayStartKey, endKey: dayEndKey } = UtilityService.getRangeKeys(filterColumn.name + 'Day');
                const dateStartValue = this.state.filters[dateStartKey];
                const dateEndValue = this.state.filters[dateEndKey];
                const dayStartValue = this.state.filters[dayStartKey];
                const dayEndValue = this.state.filters[dayEndKey];
                return dayStartValue || dayEndValue || dateStartValue || dateEndValue;
            } else {
                const { startKey, endKey } = UtilityService.getRangeKeys(filterColumn.name);
                const startValue = this.state.filters[startKey];
                const endValue = this.state.filters[endKey];
                return startValue || endValue;
            }
        } else {
            filterValue = this.state.filters[filterColumn.name];
        }

        if (Array.isArray(filterValue)) {
            return filterValue.some(x => !!x);
        } else {
            return filterValue;
        }
    };

    isFilterOpen = filterColumnName => {
        return this.state.filterOpen[filterColumnName];
    };

    toggleFilterOpen = filterColumnName => {
        this.setState(prevState => ({
            filterOpen: { ...prevState.filterOpen, [filterColumnName]: !prevState.filterOpen[filterColumnName] }
        }));
    };

    toggleFilterExpanded = () => {
        const filterExpanded = !this.state.filterExpanded;
        this.setState({ filterExpanded }, () => this.props.onFilterExpanded(filterExpanded));
    };

    clearFilter = filterColumn => {
        const filters = { ...this.state.filters };
        if (filterColumn.filterType === TableFilterType.TextRange || filterColumn.filterType === TableFilterType.NumberRange) {
            const { startKey, endKey } = UtilityService.getRangeKeys(filterColumn.name);
            filters[startKey] = '';
            filters[endKey] = '';
        } else if (filterColumn.filterType === TableFilterType.DateRange) {
            if (filterColumn.withDays) {
                const { startKey: dateStartKey, endKey: dateEndKey } = UtilityService.getRangeKeys(filterColumn.name + 'Date');
                const { startKey: dayStartKey, endKey: dayEndKey } = UtilityService.getRangeKeys(filterColumn.name + 'Day');
                filters[dateStartKey] = null;
                filters[dateEndKey] = null;
                filters[dayStartKey] = '';
                filters[dayEndKey] = '';
            } else {
                const { startKey, endKey } = UtilityService.getRangeKeys(filterColumn.name);
                filters[startKey] = null;
                filters[endKey] = null;
            }
        } else if (filterColumn.filterMultiple) {
            filters[filterColumn.name] = [];
        } else {
            filters[filterColumn.name] = '';
        }

        this.setState({ filters, filterErrors: {} }, () => {
            this.search(filters);
            this.resetDateRangeFilter(filterColumn.name);
        });
    };

    addFilterValue = filterColumnName => {
        this.setState(prevState => {
            const filters = { ...prevState.filters };
            const filterValues = filters[filterColumnName] || [];
            filterValues.push('');
            filters[filterColumnName] = filterValues;
            return { filters };
        });
    };

    dateFilterRange = dateRangeFilterValues => {
        this.setState({ filters: { ...this.state.filters, ...dateRangeFilterValues } }, () => {
            if (dateRangeFilterValues.isValid) {
                this.clearKeyUpSearchTimer();
                this.keyUpSearchTimer = setTimeout(() => this.search(), 500);
            }
        });
    };

    // event: input event
    // column: filter column
    // index: passed in when there are multiple fields to filter on
    filterChanged = (event, index) => {
        this.props.clearSelection();
        const { name, value } = event.target;

        this.setState(
            prevState => {
                const filters = { ...prevState.filters };
                let filterValue = value;
                if (index >= 0) {
                    filterValue = filters[name];
                    filterValue[index] = value;
                }
                filters[name] = filterValue;
                return { filters };
            },
            () => {
                this.clearKeyUpSearchTimer();
                this.keyUpSearchTimer = setTimeout(() => this.search(), 500);
            }
        );
    };

    rangeFilterChange = (event, filterColumn, validationCallback) => {
        event.persist();
        const { startKey, endKey } = UtilityService.getRangeKeys(filterColumn.name);
        const filters = { ...this.state.filters };
        const { name, value } = event.target;
        filters[name] = value;
        this.setState({ filters }, () => {
            const startValue = this.state.filters[startKey];
            const endValue = this.state.filters[endKey];
            const displayError = validationCallback(startValue, endValue);
            this.setErrors(filterColumn, displayError, event);
        });
    };

    resetDateRangeFilter = columnName => {
        const dateRef = this[columnName + 'Ref'];
        if (dateRef && dateRef.current) {
            dateRef.current.initialise();
        }
    };

    resetFilter = () => {
        const filters = this.getInitialFilterValues(true);
        this.setState({ filters, filterErrors: {} });
        this.props.tableColumns
            .filter(x => x.filterType === TableFilterType.DateRange)
            .forEach(c => {
                this.resetDateRangeFilter(c.name);
            });
        return filters;
    };

    resetFilterAndSearch = () => {
        const filters = this.resetFilter();
        this.search(filters);
    };

    search = filters => {
        const searchFilters = { ...(filters || this.state.filters) };
        for (let property in searchFilters) {
            if (Array.isArray(searchFilters[property])) {
                searchFilters[property] = searchFilters[property].filter(x => x);
            }
        }
        this.props.onSearch(searchFilters);
    };

    clearKeyUpSearchTimer = () => {
        if (this.keyUpSearchTimer) {
            clearTimeout(this.keyUpSearchTimer);
        }
    };

    setErrors = (filterColumn, displayError, event) => {
        let errorKey = filterColumn.name;
        const filterErrors = { ...this.state.filterErrors };
        filterErrors[errorKey] = displayError;
        if (!displayError) {
            this.filterChanged(event, filterColumn);
        }
        this.setState({ filterErrors });
    };

    numberInputChange = (e, filterColumn) => {
        e.target.value = filterColumn.max
            ? e.target.value <= filterColumn.max
                ? Math.abs(e.target.value)
                : filterColumn.max
            : e.target.value;
    };

    renderRelevantInputMarkup = (filters, filterColumn) => {
        if (filterColumn.filterType === TableFilterType.List) {
            if (filterColumn.filterMultiple) {
                const filterValue = filters[filterColumn.name].length > 0 ? filters[filterColumn.name] : [''];
                return filterValue.map((value, valueIndex) => (
                    <div key={`${filterColumn.name}${valueIndex}`} className="select-wrapper filter-control">
                        <select
                            className="form-control"
                            id={filterColumn.name}
                            name={filterColumn.name}
                            value={value}
                            onChange={event => this.filterChanged(event, valueIndex)}
                        >
                            <option value="" disabled="disabled">
                                Select
                            </option>
                            {UtilityService.getFilterDropdownOptions(filterColumn).map((option, propertyIndex) => (
                                <option key={`${filterColumn.name}${valueIndex}${propertyIndex}`} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                    </div>
                ));
            } else {
                return (
                    <div className="select-wrapper filter-control">
                        <select
                            className="form-control"
                            id={filterColumn.name}
                            name={filterColumn.name}
                            value={filters[filterColumn.name]}
                            onChange={this.filterChanged}
                        >
                            <option value="" disabled="disabled">
                                Select
                            </option>
                            {UtilityService.getFilterDropdownOptions(filterColumn).map((option, index) => (
                                <option key={`${filterColumn.name}${index}`} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                    </div>
                );
            }
        } else if (filterColumn.filterType === TableFilterType.Text) {
            return (
                <div className="filter-control">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        id={filterColumn.name}
                        name={filterColumn.name}
                        value={filters[filterColumn.name]}
                        onChange={this.filterChanged}
                    />
                </div>
            );
        } else if (filterColumn.filterType === TableFilterType.TextRange) {
            const { startKey, endKey } = UtilityService.getRangeKeys(filterColumn.name);

            return (
                <>
                    <div className="filter-control">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Range start"
                            id={startKey}
                            name={startKey}
                            value={filters[startKey]}
                            onChange={e => this.rangeFilterChange(e, filterColumn, displayTextRangeError)}
                        />
                    </div>

                    <div className="filter-control">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Range end"
                            id={endKey}
                            name={endKey}
                            value={filters[endKey]}
                            onChange={e => this.rangeFilterChange(e, filterColumn, displayTextRangeError)}
                        />
                        <div className={'range-validation ' + (this.state.filterErrors[filterColumn.name] ? 'is-visible' : 'is-hidden')}>
                            Please populate both fields, otherwise this filter is ignored.
                        </div>
                    </div>
                </>
            );
        } else if (filterColumn.filterType === TableFilterType.NumberRange) {
            const { startKey, endKey } = UtilityService.getRangeKeys(filterColumn.name);

            return (
                <>
                    <div className="filter-control">
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Range start"
                            id={startKey}
                            name={startKey}
                            value={filters[startKey]}
                            min={filterColumn.min}
                            max={filterColumn.max}
                            onInput={e => this.numberInputChange(e, filterColumn)}
                            onChange={e => this.rangeFilterChange(e, filterColumn, displayNumberRangeError)}
                        />
                    </div>

                    <div className="filter-control">
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Range end"
                            id={endKey}
                            name={endKey}
                            value={filters[endKey]}
                            min={filterColumn.min}
                            max={filterColumn.max}
                            onInput={e => this.numberInputChange(e, filterColumn)}
                            onChange={e => this.rangeFilterChange(e, filterColumn, displayNumberRangeError)}
                        />
                        <div className={'range-validation ' + (this.state.filterErrors[filterColumn.name] ? 'is-visible' : 'is-hidden')}>
                            Please populate both fields, otherwise this filter is ignored.
                        </div>
                    </div>
                </>
            );
        } else if (filterColumn.filterType === TableFilterType.DateRange) {
            return (
                <DateRangeFilter
                    filterColumn={filterColumn}
                    dateFilterRange={this.dateFilterRange}
                    filters={filters}
                    ref={this[filterColumn.name + 'Ref']}
                />
            );
        }
    };

    render() {
        const { filters, filterExpanded } = this.state;
        const { filterButtonText, searchError, selectedCount, pageDetail, enableSelection, clearSelection } = this.props;
        return (
            <>
                <FilterInfo filterColumns={this.filterTableColumns} filters={filters} />

                <div className="row align-items-center">
                    {this.isAnyFilterActive() ? (
                        <>
                            <div className="col">
                                <button onClick={this.toggleFilterExpanded} className={`btn btn-filter filter-active mb-3`}>
                                    EDIT FILTERS &nbsp; <i className="fal fa-pencil" />
                                </button>
                                <Link to="#" onClick={this.resetFilterAndSearch} className="clear-links">
                                    CLEAR FILTERS
                                </Link>
                            </div>
                        </>
                    ) : (
                        <div className="col">
                            <button onClick={this.toggleFilterExpanded} className={`btn btn-filter brd-primary clr-primary mb-3`}>
                                {filterButtonText || 'Filter'} &nbsp; <i className="fal fa-search" />
                            </button>
                        </div>
                    )}
                    <div className="col" />
                    <div className="col">
                        {enableSelection && (
                            <div className="float-right">
                                <strong>SELECTED: </strong> {selectedCount} &nbsp;
                                {
                                    <Link
                                        to="#"
                                        onClick={clearSelection}
                                        className={`clear-links ${selectedCount > 0 ? 'visible' : 'invisible'}`}
                                    >
                                        CLEAR SELECTION
                                    </Link>
                                }
                            </div>
                        )}
                    </div>
                    <div className="col">
                        <div className="float-right">
                            {pageDetail.totalCount && <strong>MATCHES: </strong>}
                            {pageDetail.totalCount && `${pageDetail.totalResults} of ${pageDetail.totalCount}`}
                        </div>
                    </div>
                </div>

                <div className={`filter-sidebar${filterExpanded ? ' filter-expanded' : ''}`}>
                    <div className="d-flex flex-row-reverse">
                        <button onClick={this.toggleFilterExpanded} className="btn btn-filter-close filter-expanded">
                            <i className="fal fa-times" />
                        </button>
                    </div>

                    {searchError && (
                        <div className="invalid-feedback" style={{ display: 'block' }}>
                            {searchError}
                        </div>
                    )}
                    {this.isAnyFilterActive() && (
                        <div className="form-group">
                            <Link to="#" className="clear-filter" onClick={this.resetFilterAndSearch}>
                                Clear all
                            </Link>
                        </div>
                    )}
                    {this.filterTableColumns.map((filterColumn, index) => (
                        <div
                            key={`${filterColumn.name}${index}`}
                            className={`form-group
                                    ${this.isFilterOpen(filterColumn.name) ? ' filter-expanded' : ''}
                                    ${this.isFilterActive(filterColumn) ? ' filter-active' : ''}`}
                        >
                            <div className="pointer" onClick={() => this.toggleFilterOpen(filterColumn.name)}>
                                <label className="pointer" htmlFor={filterColumn.name}>
                                    {filterColumn.text}
                                </label>
                                <i className="fal fa-chevron-down float-right" />
                            </div>
                            {this.isFilterOpen(filterColumn.name) && (
                                <>
                                    <div key={`${filterColumn.name}`}>{this.renderRelevantInputMarkup(filters, filterColumn)}</div>

                                    {this.isFilterActive(filterColumn) && (
                                        <>
                                            <Link to="#" className="clear-filter" onClick={() => this.clearFilter(filterColumn)}>
                                                Clear
                                            </Link>
                                            {filterColumn.filterMultiple && (
                                                <Link to="#" className="add-filter" onClick={() => this.addFilterValue(filterColumn.name)}>
                                                    Add filter
                                                </Link>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    ))}
                </div>

                <RightPane
                    isVisible={this.props.rightPaneExpanded}
                    title="Are you sure?"
                    message="You are about to issue commands the selected devices."
                    confirmBtnText="I am sure"
                    confirmBtnFn={this.props.rightPaneConfirmBtnFn}
                    cancelBtnText="Cancel"
                    cancelBtnFn={this.props.toggleRightPane}
                    commandChangeFn={value => this.props.rightPaneCommandChangeFn(value)}
                    commandId={this.props.commandId}
                />
            </>
        );
    }
}
