export const Roles = {
    DeviceManager: 'Device Manager',
    FirmwareManager: 'Firmware Manager',
    SystemAdmin: 'System Admin',
    CompanyAdmin: 'Company Admin',
    InventoryManager: 'Inventory Manager',
    ConfigurationManager: 'Configuration Manager',
    CommandsManager: 'Commands Manager',
    SimCardManager: 'Sim Card Manager',
    DMAccess: 'DM Access'
};

export const DeviceAlerts = {
    NotConnectedSinceLastMonth: 1,
    Tampered: 2,
    LessThanTenPercentBatteryLeft: 3,
    OutOfWarranty: 4,
    BatteryExpiring: 5
};
