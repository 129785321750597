const displayTextRangeError = (startValue, endValue) => {
    if (startValue && endValue) {
        return false;
    } else if (!startValue && endValue) {
        return true;
    } else if (startValue && !endValue) {
        return true;
    }
    return false;
};

const displayNumberRangeError = (startValue, endValue) => {
    const start = +startValue;
    const end = +endValue;
    if (isNaN(start) || isNaN(end)) {
        return true;
    } else if (start > end) {
        return true;
    } else if (start && end) {
        return false;
    } else if (!start && end) {
        return true;
    } else if (start && !end) {
        return true;
    }
    return false;
};

const displayDateRangeError = (startValue, endValue) => {
    if (!startValue && !endValue) {
        return false;
    } else if (startValue && !endValue) {
        return true;
    } else if (!startValue && endValue) {
        return true;
    } else if (startValue > endValue) {
        return true;
    }
    return false;
};

export { displayTextRangeError, displayNumberRangeError, displayDateRangeError };
