import React from 'react';
import { HttpService, UtilityService } from '../../services';
import { deviceConstants } from './device-constants';

export class DeviceConfigurationModal extends React.Component {
    state = {
        keys: [],
        modelState: ''
    };

    componentDidMount() {
        HttpService.get('/Device/GetConfigurationKeys').then(response =>
            this.setState({
                keys: response.data
            })
        );
    }

    updateConfiguration = event => {
        event.preventDefault();
        var configurationKeys = {};
        for (let i = 0; i < event.target.length - 1; i++) {
            if (event.target[i].className === 'form-control Int16_4') {
                configurationKeys[event.target[i].id] = [];
                configurationKeys[event.target[i].id].push(parseInt(event.target[i + 3].value));
                configurationKeys[event.target[i].id].push(parseInt(event.target[i + 2].value));
                configurationKeys[event.target[i].id].push(parseInt(event.target[i + 1].value));
                configurationKeys[event.target[i].id].push(parseInt(event.target[i].value));
                i = i + 3;
            } else {
                configurationKeys[event.target[i].id] = event.target[i].value;
            }
        }
        var config = JSON.stringify(configurationKeys);
        if (UtilityService.isValidJson(config)) {
            const { selected } = this.props;
            const deviceCount = selected.selectedCount;
            const disclaimerText = deviceConstants.disclaimerText.replace('{deviceCount}', deviceCount);
            if (window.confirm(disclaimerText)) {
                const { selectedIds } = this.props.selected;
                HttpService.post('/Device/UpdateConfiguration', {
                    filter: { selectedIds },
                    jsonConfiguration: config
                })
                    .then(() => this.props.onSuccess(deviceCount))
                    .catch(this.handleError);
            }
        } else {
            this.setState({ modelState: deviceConstants.errorMessages.invalidJson });
        }
    };

    handleError = err => {
        if (err.response) {
            const status = err.response.status;
            if (status === 400) {
                document.body.children.root.lastChild.classList.remove('loading-indicator');
                switch (err.response.data) {
                    case 'InvalidJson':
                        this.setState({ modelState: deviceConstants.errorMessages.invalidJson });
                        break;
                    case 'VersionMissing':
                        this.setState({ modelState: deviceConstants.errorMessages.versionMissing });
                        break;
                    default:
                        const error = err.response.data.JsonConfiguration && err.response.data.JsonConfiguration[0];
                        this.setState({ modelState: error });
                        break;
                }
            } else {
                this.setState({ modelState: deviceConstants.errorMessages.unknownError });
            }
        }
    };

    getConfigurationKeyValueFormat = configurationKey => {
        switch (configurationKey.dataType) {
            case 1:
            case 3:
            case 4:
            case 6:
                return (
                    <div className="keyValue">
                        <input
                            type="number"
                            max={configurationKey.max}
                            min={configurationKey.min}
                            className="form-control"
                            id={configurationKey.keyName}
                            defaultValue={configurationKey.defaultValue}
                            onChange={this.handleChange}
                        />
                    </div>
                );
            case 2:
                var value = configurationKey.defaultValue.replace(/[\[\] ]/g, '');
                var splitValue = value.split(',');
                return (
                    <div className="keyValue">
                        <input type="number" className="form-control Int16_4" id={configurationKey.keyName} defaultValue={splitValue[3]} />
                        <input type="number" className="form-control Int16_4" id={configurationKey.keyName} defaultValue={splitValue[2]} />
                        <input type="number" className="form-control Int16_4" id={configurationKey.keyName} defaultValue={splitValue[1]} />
                        <input type="number" className="form-control Int16_4" id={configurationKey.keyName} defaultValue={splitValue[0]} />
                    </div>
                );
            case 5:
                return (
                    <div className="keyValue">
                        <input
                            type="text"
                            className="form-control"
                            maxLength={configurationKey.max}
                            id={configurationKey.keyName}
                            defaultValue={configurationKey.defaultValue}
                        />
                    </div>
                );
            default:
                return (
                    <div className="keyValue">
                        <input
                            type="text"
                            className="form-control"
                            id={configurationKey.keyName}
                            defaultValue={configurationKey.defaultValue}
                        />
                    </div>
                );
        }
    };

    handleChange = event => {
        if (event.target.max != '' && event.target.min != '') {
            const value = Math.max(event.target.min, Math.min(event.target.max, Number(event.target.value)));
            event.target.value = value;
        }
    };

    render() {
        const { keys, modelState } = this.state;
        if (modelState) {
            return (
                <React.Fragment>
                    <div className="modal-backdrop fade in show" />
                    <div className="modal fade in show">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">DEVICE CONFIGURATION</h4>
                                    <button onClick={this.props.onClose} className="close" data-dismiss="modal" aria-label="Close">
                                        <i className="fal fa-times" />
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>{modelState}</p>
                                </div>
                                <div className="modal-footer">
                                    <div className="modal-btn-container">
                                        <button onClick={this.props.onClose} className="btn btn-error">
                                            CANCEL &nbsp; <i className="fal fa-times" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="modal-backdrop fade in show" />
                    <div className="modal fade in show">
                        <div className="modal-dialog device-config">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">DEVICE CONFIGURATION</h4>
                                    <button onClick={this.props.onClose} className="close" data-dismiss="modal" aria-label="Close">
                                        <i className="fal fa-times" />
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <form id="configform" className="configuration-table" onSubmit={this.updateConfiguration}>
                                        <div className="config-version">
                                            <label className="keyName">
                                                <strong>DeviceConfigurationVersion</strong>
                                            </label>
                                            <div className="keyValue">
                                                <input type="text" className="form-control" id="DeviceConfigurationVersion" />
                                            </div>
                                        </div>
                                        {keys.map(configurationKey => (
                                            <div>
                                                <label className="keyName">
                                                    <strong>{configurationKey.keyName}</strong>
                                                </label>
                                                {this.getConfigurationKeyValueFormat(configurationKey)}
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <div className="modal-btn-container">
                                        <button form="configform" type="submit" className="btn btn-success">
                                            SUBMIT &nbsp; <i className="fal fa-check" />
                                        </button>
                                        <button onClick={this.props.onClose} className="btn btn-error">
                                            CLOSE &nbsp; <i className="fal fa-times" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }
}
