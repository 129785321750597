import { TableColumnType } from '../components';

const formatResult = (result, tableColumn) => {
    switch (tableColumn.dataType) {
        case TableColumnType.Date:
            return toDate(result[tableColumn.name]);
        case TableColumnType.DateTime:
            return toDateTime(result[tableColumn.name]);
        case TableColumnType.YesNo:
            return toYesNo(result[tableColumn.name]);
        case TableColumnType.Text:
        default:
            return getNestedProperty(result, tableColumn.name) || '';
    }
};

const getNestedProperty = (obj, nestedProperty) => {
    nestedProperty.split('.').forEach(property => (obj = obj[property]));
    return obj;
};

const toYesNo = bool => (bool ? 'Yes' : 'No');

const toDate = dateString => new Date(dateString).toLocaleDateString();

const toDateTime = dateString => {
    if (dateString) {
        const date = new Date(dateString);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    }
    return null;
};

const lastConnectedString = dateString => {
    if (!dateString) {
        return dateString;
    }
    const date = new Date(dateString);
    let days = Math.round((new Date() - date) / (1000 * 60 * 60 * 24));
    if (days > 0) {
        return `Last used ${days} day${days === 1 ? '' : 's'} ago`;
    }

    let hours = Math.round((new Date() - date) / (1000 * 60 * 60));
    if (hours > 0) {
        return `Last used ${hours} hour${hours === 1 ? '' : 's'} ago`;
    }

    let mins = Math.round((new Date() - date) / (1000 * 60));
    if (mins > 0) {
        return `Last used ${mins} min${mins === 1 ? '' : 'utes'} ago`;
    }

    let seconds = Math.round((new Date() - date) / 1000);
    if (seconds > 0) {
        return `Last used ${seconds} second${seconds === 1 ? '' : 's'} ago`;
    }
    return `Unknown`;
};

const FormatService = {
    formatResult,
    lastConnectedString
};

export { FormatService };
