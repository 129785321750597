import React, { Component } from 'react';

import { StaticTableRow } from './StaticTableRow';
import { Pagination, TablePageSize, TableColumnOrder } from '..';

export class StaticTable extends Component {
    state = {
        selectAll: false,
        orderDetail: {
            orderByColumn: this.props.columns[0].name,
            orderByDirection: TableColumnOrder.Descending
        }
    };

    getSelectedCount = () => {
        return this.props.data.filter(x => x.selected).length;
    };

    onSingleSelect = resultId => {
        this.props.onSingleSelect(resultId);
    };

    onSelectAll = selectAll => {
        this.setState(prevState => {
            return { selectAll };
        }, this.props.onSelectAll(selectAll));
    };

    getTableColumns = () => this.props.tableColumns.filter(x => !x.filterOnly);

    getTableColumnCount = () => {
        const defaultTableColumnCount = 2;
        return this.getTableColumns().length + (this.anyAlerts() ? 1 : 0) + defaultTableColumnCount;
    };

    getSortedByClass = columnName => {
        const classNames = ['pointer'];
        const { orderDetail } = this.state;
        if (orderDetail) {
            if (columnName === orderDetail.orderByColumn) {
                classNames.push('sortBy');
                if (orderDetail.orderByDirection === TableColumnOrder.Descending) {
                    classNames.push('sortByDescending');
                }
            }
        }
        return classNames.join(' ');
    };

    sortResults = columnName => {
        const { data } = this.props;

        let orderByDirection = TableColumnOrder.Ascending;

        if (columnName === this.state.orderDetail.orderByColumn) {
            orderByDirection =
                this.state.orderDetail.orderByDirection === TableColumnOrder.Descending
                    ? TableColumnOrder.Ascending
                    : TableColumnOrder.Descending;
        }

        const sortedData = data.sort((a, b) => {
            const aText = a[columnName];
            const bText = b[columnName];
            const orderByDescending = orderByDirection === TableColumnOrder.Descending;
            if (aText < bText) {
                return -1 * (orderByDescending ? -1 : 1);
            }
            if (aText > bText) {
                return 1 * (orderByDescending ? -1 : 1);
            }
            return 0;
        });

        let orderDetail = {
            orderByColumn: columnName,
            orderByDirection
        };

        this.setState({ data: sortedData, orderDetail });
    };

    isCurrentColumnOrdered = columnName => {
        return columnName === this.state.orderDetail.orderByColumn;
    };

    render() {
        const { selectAll, filterExpanded } = this.state;
        const { data, columns, allowSelection, allowSelectAll, footer, changePage, changePerPage, pageDetail, tableText } = this.props;

        const orderBy = columns.find(x => x.orderBy);
        if (orderBy) {
            this.orderByColumn = orderBy.name;
        }

        return (
            <>
                {data && data.length > 0 && (
                    <div
                        className={`container-fluid table-container 
                            ${filterExpanded ? 'filter-expanded' : ''} 
                            ${this.props.rightPaneExpanded ? 'right-pane-expanded' : ''}`}
                    >
                        <div className="row">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {allowSelection && (
                                                <th className="flex-center">
                                                    <label
                                                        className="checkbox-container"
                                                        style={{ visibility: allowSelectAll ? 'visible' : 'hidden' }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={selectAll}
                                                            onChange={e => this.onSelectAll(e.target.checked)}
                                                        />
                                                        <span className="checkmark" />
                                                    </label>
                                                </th>
                                            )}
                                            {columns.map(tableColumn => (
                                                <th
                                                    key={tableColumn.name}
                                                    className={this.getSortedByClass(tableColumn.name)}
                                                    onClick={() => this.sortResults(tableColumn.name)}
                                                >
                                                    {tableColumn.text} &nbsp;
                                                    {this.isCurrentColumnOrdered(tableColumn.name) && <i className="fal fa-chevron-down" />}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((row, index) => (
                                            <StaticTableRow
                                                key={`TableRow${index}`}
                                                columns={columns}
                                                data={row}
                                                allowSelection={allowSelection}
                                                onSelect={this.onSingleSelect}
                                            />
                                        ))}
                                    </tbody>
                                    {footer}
                                </table>
                            </div>
                        </div>

                        {pageDetail && (
                            <div className="table-controls">
                                <TablePageSize
                                    pageDetail={pageDetail}
                                    changePerPage={changePerPage}
                                    perPageLabel={tableText.perPageLabel}
                                />
                                <Pagination pageDetail={pageDetail} changePage={changePage} />
                            </div>
                        )}
                    </div>
                )}
            </>
        );
    }
}
