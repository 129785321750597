import React, { useState, useEffect } from 'react';

import { Modal } from '../../components';

export const EditGroup = ({ groupToEdit, confirm, cancel, error }) => {
    const [newGroupName, setNewGroupName] = useState('');

    const isNew = () => {
        return groupToEdit.id === 0;
    };

    const onConfirm = () => {
        confirm({ id: groupToEdit.id, groupName: newGroupName });
    };

    useEffect(() => {
        setNewGroupName(groupToEdit.groupName);
    }, [groupToEdit]);

    return (
        <Modal title={isNew() ? 'ADD GROUP' : 'EDIT GROUP'} okText={isNew() ? 'ADD' : 'OK'} onClose={onConfirm} onCancel={cancel}>
            <div className="form-group">
                <label htmlFor="group-name">GROUP NAME</label>
                <input type="text" className="form-control" value={newGroupName} onChange={e => setNewGroupName(e.target.value)} />
                {error && <div className="invalid-feedback d-block">{error}</div>}
            </div>
        </Modal>
    );
};
