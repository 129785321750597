import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { HttpService } from '../../services';
import { DeviceTypes, CustomersDropdown } from '../../components';
import { DashboardAlerts } from './DashboardAlerts';
import { DeviceAlerts } from '../../models';

export class Home extends Component {
    state = {
        deviceOverviews: [],
        deviceGroups: [],
        deviceNotifications: []
    };

    componentDidMount() {
        this.search();
    }

    search = () => {
        const filter = {};
        if (this.deviceType) {
            filter.deviceType = this.deviceType;
        }
        if (this.customerId) {
            filter.customerId = this.customerId;
        }

        Promise.all([HttpService.get('/Group/GetAllGroupsDetailed', filter), HttpService.get('/Dashboard/GetDashboard', filter)]).then(
            ([groupsResponse, dashboardResponse]) => {
                const dashboard = dashboardResponse.data;
                const groups = groupsResponse.data;

                this.setState({
                    deviceGroups: groups,
                    deviceOverviews: [
                        { count: dashboard.shippedDevicesCount, text: 'SHIPPED DEVICES', stage: 'Shipped' },
                        { count: dashboard.inUseDevicesCount, text: 'IN USE DEVICES', stage: 'In Use' }
                    ],
                    deviceNotifications: [
                        {
                            count: dashboard.notConnectedInLastMonthCount,
                            text: 'have not connected within the last month',
                            type: DeviceAlerts.NotConnectedSinceLastMonth
                        },
                        { count: dashboard.tamperedCount, text: 'may have been tampered with', type: DeviceAlerts.Tampered },
                        {
                            count: dashboard.lessThanTenPercentBatteryCount,
                            text: 'have less than 10% battery remaining',
                            type: DeviceAlerts.LessThanTenPercentBatteryLeft
                        },
                        {
                            count: dashboard.batteryOverUsageCount,
                            text: 'are likely to expire too quickly due to over-use',
                            type: DeviceAlerts.BatteryExpiring
                        }
                    ]
                });
            }
        );
    };

    getFilters = group => {
        const filters = {};
        if (this.deviceType) {
            filters.deviceType = this.deviceType;
        }
        if (this.customerId) {
            filters.customerId = this.customerId;
        }
        if (group) {
            filters.groups = [group];
        }
        return filters;
    };

    deviceTypeChanged = deviceType => {
        this.deviceType = deviceType;
        this.search();
    };

    customerUpdated = customerId => {
        this.customerId = customerId;
        this.search();
    };

    render() {
        const { deviceOverviews, deviceGroups, deviceNotifications } = this.state;

        return (
            <div className="dashboard">
                <div className="dashboard-controls">
                    <form className="form-inline">
                        <div className="form-group">
                            <DeviceTypes onChange={this.deviceTypeChanged} />
                        </div>
                        <div className="form-group">
                            <CustomersDropdown onChange={this.customerUpdated} />
                        </div>
                    </form>
                </div>

                <div className="dashboard-section-wrapper">
                    <div className="dashboard-section dashboard-section-half">
                        <div className="dashboard-title">DEVICES OVERVIEW</div>
                        <div className="dashboard-overview-wrapper">
                            {deviceOverviews.map((deviceOverview, index) => (
                                // To enable the click through again, change div to Link, and uncomment the code below
                                <Link
                                    to={{
                                        pathname: '/Devices',
                                        filters: { productLifecycleStage: this.props.setFilters(deviceOverview.stage) }
                                    }}
                                    key={`${deviceOverview.text}}${index}`}
                                    className="dashboard-overview"
                                >
                                    <div className="dashboard-overview-title">{deviceOverview.count}</div>
                                    <span>{deviceOverview.text}</span>
                                </Link>
                            ))}
                        </div>
                    </div>
                    <div className="dashboard-section dashboard-section-half">
                        <div className="dashboard-title">DEVICE GROUPS</div>
                        <div className="device-group-wrapper">
                            {deviceGroups.map(deviceGroup => (
                                <Link
                                    to={{
                                        pathname: '/Devices',
                                        filters: { groups: [this.props.setFilters(deviceGroup.groupName)] }
                                    }}
                                    key={deviceGroup.groupName}
                                    className="device-group clr-primary"
                                >
                                    <div className="device-group-title">
                                        {deviceGroup.numberOfDevicesInGroup} {deviceGroup.groupName}
                                    </div>
                                    <div className="device-group-link">
                                        VIEW DEVICES <i className="fal fa-eye" />
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>

                    {/* To show alerts remove <div> */}
                    <div hidden>
                        <DashboardAlerts alerts={deviceNotifications} />
                    </div>
                </div>
            </div>
        );
    }
}
