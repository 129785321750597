import React, { useState, useEffect } from 'react';

import { HttpService } from '../../services';
import { EditableList } from '../../components';
import { EditGroup } from './EditGroup';
import { AuthConsumer } from '../../contexts';

export const GroupManagement = () => {
    const [groups, setGroups] = useState(null);
    const [groupToEdit, setGroupToEdit] = useState(null);
    const [error, setError] = useState('');
    const nameLimit = 20;

    const columns = [
        {
            name: 'groupName',
            displayName: 'Group name'
        },
        {
            name: 'devicesInGroupCount',
            displayName: 'Device count'
        },
        {
            name: 'editButtons'
        }
    ];

    const mapGroups = groups => {
        return groups.map(group => {
            return {
                row: group,
                rowContent: [group.groupName, group.devicesInGroupCount]
            };
        });
    };

    const getGroupList = () => {
        HttpService.get('/Group').then(res => setGroups(mapGroups(res.data)));
    };

    const handleError = error => {
        if (error.response.status === 400 && error.response.data === 'GroupAlreadyExists') {
            setError('Another group with this name already exists.');
        }
    };

    const confirmGroupEditing = group => {
        if (group.groupName && group.groupName.trim().length > 0) {
        
            if (group.groupName.length <= nameLimit) {
                const apiAction =
                    group.id > 0
                        ? HttpService.put(`/Group/${group.id}`, { updatedGroupName: group.groupName })
                        : HttpService.post('/Group', { newGroupName: group.groupName });

                apiAction.then(() => {
                    getGroupList();
                    setGroupToEdit(null);
                    setError('');
                }, handleError);
            } else {
                setError(`Group names cannot be more then ${nameLimit} characters.`);
            }
        }
        else{
            setError('Please enter a unique valid group name.');
        }
    };

    const setGroup = group => {
        setError('');
        setGroupToEdit(group);
    };

    const onDelete = group => {
        if (
            window.confirm(
                `Are you sure you want to delete the group: ${group.groupName}? \n This group has ${
                    group.devicesInGroupCount
                } devices linked.`
            )
        ) {
            HttpService.delete(`/Group/${group.id}`).then(getGroupList);
        }
    };

    useEffect(() => {
        getGroupList();
    }, []);

    const rolescheck = authContext => {
        const { hasSystemAdminRole } = authContext.actions;
        return [
            {
                hide: !hasSystemAdminRole()
            }
        ];
    };

    return (
        <>
            <AuthConsumer>
                {context => {
                    return (
                        <div>
                            {rolescheck(context).map(div => (
                                <div className="dashboard" hidden={div.hide}>
                                    <div className="row pb-2">
                                        <div className="col-8">
                                            <h2>GROUP MANAGEMENT</h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-8 pb-2 text-right">
                                            <button
                                                className="btn btn-filter brd-primary clr-primary"
                                                onClick={() => setGroup({ id: 0, groupName: '' })}
                                            >
                                                ADD GROUP &nbsp;
                                                <i className="fal fa-plus" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-8">
                                            <EditableList
                                                columns={columns}
                                                data={groups}
                                                onEdit={group => setGroup(group)}
                                                onDelete={onDelete}
                                            />
                                        </div>
                                        <div className="col-4" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    );
                }}
            </AuthConsumer>
            {groupToEdit && (
                <EditGroup groupToEdit={groupToEdit} confirm={confirmGroupEditing} error={error} cancel={() => setGroup(null)} />
            )}
        </>
    );
};
