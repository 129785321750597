import React from 'react';
import { FormatService } from '../../../services';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSimCard } from '@fortawesome/free-solid-svg-icons';

export const DynamicTableRow = ({
    colSpan,
    result,
    tableColumns,
    anyAlerts,
    onChecked,
    expandResult,
    toggleDeviceAuditModal,
    toggleDeviceSimExpireDateModal,
    toggleResponseModal,
    toggleDeviceMetaDataHistoryModal,
    toggleAnalyticsModal,
    enableSelection,
    RowDetailsTemplate
}) => {
    var simIcon = <div />;
    if (result.deviceSimCardDetails != null) {
        if (result.deviceSimCardDetails.isSimCardActive == true) {
            simIcon = (
                <FontAwesomeIcon
                    className="simActiveAlert"
                    icon={faSimCard}
                    title={`Sim Card Active. Expires: ${moment.utc(result.deviceSimCardDetails.simCardExpiry).format('dddd, LL')}`}
                />
            );
        } else {
            simIcon = <FontAwesomeIcon className="simNotActiveAlert" icon={faSimCard} title={`Sim Card Not Active.`} />;
        }
    }
    return (
        <>
            <tr
                className={enableSelection && `pointer ${result.detailsViewOpen && 'details-panel-open'}`}
                onClick={e => expandResult(e, result)}
            >
                {enableSelection && (
                    <td>
                        <div className="flex-center">
                            <label className="checkbox-container">
                                <input
                                    type="checkbox"
                                    checked={!!result.selected}
                                    onChange={e => onChecked(result.id, e.target.checked, result.deviceType)}
                                />
                                <span className="checkmark" />
                            </label>
                        </div>
                    </td>
                )}
                {anyAlerts && (
                    <td className="status-alerts">
                        <div className="flex-center">
                            {result.alerts && (
                                <>
                                    {result.alerts.map((alert, index) => (
                                        <i
                                            key={`${alert.text}${index}`}
                                            className={`${alert.iconClassName} ${alert.colourClassName}`}
                                            title={alert.text}
                                        />
                                    ))}
                                </>
                            )}
                            {simIcon}
                        </div>
                    </td>
                )}
                {tableColumns &&
                    tableColumns.map((tableColumn, index) => (
                        <td key={`${tableColumn.name}${index}`}>{FormatService.formatResult(result, tableColumn)}</td>
                    ))}
            </tr>
            {RowDetailsTemplate && result.detailsViewOpen && (
                <RowDetailsTemplate
                    toggleDeviceAuditModal={toggleDeviceAuditModal}
                    toggleDeviceSimExpireDateModal={toggleDeviceSimExpireDateModal}
                    toggleResponseModal={toggleResponseModal}
                    toggleDeviceMetaDataHistoryModal={toggleDeviceMetaDataHistoryModal}
                    toggleAnalyticsModal={toggleAnalyticsModal}
                    colSpan={colSpan}
                    result={result}
                />
            )}
        </>
    );
};
