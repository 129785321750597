import React from 'react';
import { Link } from 'react-router-dom';

export const DashboardAlerts = ({ alerts }) => {
    return (
        <div className="dashboard-section">
            <div className="dashboard-title">DEVICE NOTIFICATIONS</div>
            <div className="dashboard-notification-wrapper">
                {alerts.map((alert, index) => (
                    <Link key={index} to={`/devices?filters=${JSON.stringify({ deviceAlert: alert.type })}`}>
                        <svg
                            id="Layer_1"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 238.3 209.02"
                            width="125"
                            height="112.5"
                        >
                            <defs>
                                <linearGradient id="grad" x1="0%" y1="0%" x2="0%" y2="100%">
                                    <stop offset="0%" stopColor="#ec644b" />
                                    <stop offset="100%" stopColor="#f05f57" />
                                </linearGradient>
                            </defs>
                            <path
                                d="M941.36,192,832.2,381a10,10,0,0,0,8.64,15h218.32a10,10,0,0,0,8.64-15L958.64,192A10,10,0,0,0,941.36,192Z"
                                transform="translate(-830.85 -186.98)"
                                fill="url(#grad)"
                            />
                            <text x="115" y="135" textAnchor="middle" fill="white">
                                {alert.count}
                            </text>
                        </svg>
                        <div className="dashboard-notification-title">DEVICES</div>
                        <div className="dashboard-notification-text">{alert.text}</div>
                    </Link>
                ))}
            </div>
        </div>
    );
};
