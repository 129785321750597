import React from 'react';
import { TableFilterType } from './models';
import { UtilityService } from '../../services';

export const FilterInfo = ({ filters, filterColumns }) => {
    const filterInfo = () => {
        const info = filterColumns.map(filterColumn => {
            if (filterColumn.filterType === TableFilterType.Text) {
                return {
                    hasValue: !!filters[filterColumn.name],
                    key: filterColumn.text,
                    value: filters[filterColumn.name]
                };
            }

            if (filterColumn.filterType === TableFilterType.List) {
                const filterOptions = UtilityService.getFilterDropdownOptions(filterColumn);
                if (filterColumn.filterMultiple) {
                    const hasValue = filters[filterColumn.name] && filters[filterColumn.name].length > 0;
                    return {
                        hasValue,
                        key: filterColumn.text,
                        value: filters[filterColumn.name].join(',')
                    };
                } else {
                    // eslint-disable-next-line
                    const matchedOption = filterOptions.find(x => x.value == filters[filterColumn.name]);
                    return {
                        hasValue: filters[filterColumn.name],
                        key: filterColumn.text,
                        value: matchedOption && matchedOption.text
                    };
                }
            }

            if (filterColumn.filterType === TableFilterType.TextRange || filterColumn.filterType === TableFilterType.NumberRange) {
                const { startKey, endKey } = UtilityService.getRangeKeys(filterColumn.name);
                const hasValue = !!filters[startKey] && !!filters[endKey];

                return {
                    hasValue,
                    key: filterColumn.text,
                    value: `${filters[startKey]} - ${filters[endKey]}`
                };
            }

            if (filterColumn.filterType === TableFilterType.DateRange && !filterColumn.withDays) {
                const { startKey, endKey } = UtilityService.getRangeKeys(filterColumn.name);
                const hasValue = !!filters[startKey] && !!filters[endKey];

                return hasValue
                    ? {
                          hasValue,
                          key: filterColumn.text,
                          value: `${UtilityService.localDateFormat(filters[startKey])} - ${UtilityService.localDateFormat(filters[endKey])}`
                      }
                    : {};
            }

            if (filterColumn.filterType === TableFilterType.DateRange && filterColumn.withDays) {
                const { startKey: dateStartKey, endKey: dateEndKey } = UtilityService.getRangeKeys(filterColumn.name + 'Date');
                const { startKey: dayStartKey, endKey: dayEndKey } = UtilityService.getRangeKeys(filterColumn.name + 'Day');
                const hasDateValue = !!filters[dateStartKey] && !!filters[dateEndKey];
                const hasDayValue = !!filters[dayStartKey] && !!filters[dayEndKey];
                const hasValue = hasDateValue || hasDayValue;
                return hasValue
                    ? {
                          hasValue,
                          key: filterColumn.text,
                          value:
                              (hasDateValue &&
                                  `${UtilityService.localDateFormat(filters[dateStartKey])} - ${UtilityService.localDateFormat(
                                      filters[dateEndKey]
                                  )}`) ||
                              (hasDayValue && `${filters[dayStartKey]} - ${filters[dayEndKey]}`)
                      }
                    : {};
            }

            return {
                hasValue: false
            };
        });
        return info;
    };

    const anyFilters = filters && filterInfo().some(x => x.hasValue);
    return (
        <div className="row mb-5">
            <div className="col">
                {anyFilters && (
                    <>
                        <strong>FILTER:</strong>&nbsp;
                        {filterInfo()
                            .filter(f => f.hasValue)
                            .map((item, index) => (
                                <span key={index}>
                                    <strong>{item.key}:</strong> {item.value}
                                </span>
                            ))
                            .reduce((prev, curr) => [prev, prev && ', ', curr], null)}
                    </>
                )}
            </div>
            <div className="col" />
        </div>
    );
};
