import React from 'react';
import { Link } from 'react-router-dom';
import { AuthConsumer } from '../contexts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSimCard } from '@fortawesome/free-solid-svg-icons';

export class SidebarTabs extends React.Component {
    activeClass = locations => {
        return `flex-sm-fill text-sm-center nav-link${
            locations.some(location => window.location.pathname.toLowerCase().startsWith(location.toLowerCase())) ? ' active' : ''
        }`;
    };

    rolescheck = authContext => {
        const { hasInventoryRole } = authContext.actions;
        const { hasSystemAdminRole } = authContext.actions;
        const { hasSimCardManagerRole } = authContext.actions;
        const { hasAccessRole } = authContext.actions;
        var hasAnInventoryRole = hasInventoryRole() || hasSystemAdminRole();
        var hasASimRole = hasSystemAdminRole() || hasSimCardManagerRole();
        if (hasAccessRole() == false && window.location.pathname != '/NotAuthorised') {
            window.location.href = './NotAuthorised';
        }
        return [
            {
                invhide: !hasAnInventoryRole,
                simhide: !hasASimRole,
                noRolesHide: !hasAccessRole()
            }
        ];
    };

    render() {
        return (
            <AuthConsumer>
                {context => {
                    return (
                        <div>
                            {this.rolescheck(context).map(div => (
                                <div hidden={div.noRolesHide}>
                                    <nav className="sidebar-tabs nav nav-pills flex-row flex-sm-column font-ng">
                                        <Link to="/Home" className={this.activeClass(['/Home'])}>
                                            <i className="fal fa-home" />
                                            HOME
                                        </Link>

                                        <Link to="/Devices" className={this.activeClass(['/Devices', '/EditDevice'])}>
                                            <i className="fal fa-tablet-android" />
                                            DEVICES
                                        </Link>
                                        <div hidden={div.invhide}>
                                            <Link
                                                to="/CustomerHierarchy"
                                                className={this.activeClass(['/CustomerHierarchy', '/StockSummary'])}
                                            >
                                                <br />
                                                <i className="fal fa-inventory" />
                                                INVENTORY
                                                <i />
                                            </Link>
                                        </div>
                                        <div hidden={div.simhide}>
                                            <Link to="/SimCardActivation" className={this.activeClass(['/SimCardActivation'])}>
                                                <i className="fas" />
                                                <FontAwesomeIcon className="simCardActivation" icon={faSimCard} />
                                                Sim Card Activation
                                                <i />
                                            </Link>
                                        </div>
                                        <Link to="/Audits" className={this.activeClass(['/Audits'])}>
                                            <i className="fal fa-history" />
                                            AUDITS
                                        </Link>
                                    </nav>
                                </div>
                            ))}
                        </div>
                    );
                }}
            </AuthConsumer>
        );
    }
}
