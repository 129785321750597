import React, { useState, useEffect } from 'react';
import { HttpService } from '../../services';
import { Link } from 'react-router-dom';
import { sortBy } from 'lodash';

import { FormatService, UtilityService } from '../../services';
import { BatteryUsageModal } from './BatteryUsageModal';
import { AuthConsumer } from '../../contexts';
import Moment from 'react-moment';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSimCard } from '@fortawesome/free-solid-svg-icons';
import { TransitionMotion } from '@serprex/react-motion/lib/react-motion';
import { spring } from '@serprex/react-motion';

export const DeviceDetailsPanel = ({
    colSpan,
    result,
    toggleDeviceAuditModal,
    toggleDeviceSimExpireDateModal,
    toggleResponseModal,
    toggleDeviceMetaDataHistoryModal,
    toggleAnalyticsModal
}) => {
    const [items, setItems] = useState([{ key: 'slide-panel', maxHeight: 0, opacity: 0, paddingTop: 0, paddingBottom: 0 }]);
    const [displayBatteryUsageModal, setDisplayBatteryUsageModal] = useState(false);

    var state = {
        hide: true,
        simhide: true,
        buttonHide: null
    };

    var simIcon = <div />;
    if (result.deviceSimCardDetails != null && result.deviceSimCardDetails != undefined) {
        if (result.deviceSimCardDetails.isSimCardActive == true) {
            simIcon = (
                <div>
                    <FontAwesomeIcon className="simActiveStatus" icon={faSimCard} />
                    <span>Sim Card Active. Expires: {moment.utc(result.deviceSimCardDetails.simCardExpiry).format('dddd, LL')}</span>
                </div>
            );
        } else {
            simIcon = (
                <div>
                    <FontAwesomeIcon className="simNotActiveStatus" icon={faSimCard} />
                    <span>Sim Card Not Active</span>
                </div>
            );
        }
    }

    useEffect(() => {
        const presetStyles = { stiffness: 210, damping: 20 };
        setItems([
            {
                key: 'slide-panel',
                opacity: spring(1),
                maxHeight: spring(2000, presetStyles),
                paddingTop: spring(50, presetStyles),
                paddingBottom: spring(50, presetStyles)
            }
        ]);

        return () => {
            setItems([{ key: 'slide-panel', maxHeight: 0, opacity: 0, paddingTop: 0, paddingBottom: 0 }]);
        };
    }, []);

    const willLeave = () => {
        return {
            maxHeight: spring(0),
            opacity: spring(0),
            paddingTop: spring(0),
            paddingBottom: spring(0)
        };
    };

    const getBatteryIcon = batteryLevel => {
        if (batteryLevel === 100) {
            return 'full';
        }
        if (batteryLevel >= 75) {
            return 'three-quarters';
        }
        if (batteryLevel >= 50) {
            return 'half';
        }
        if (batteryLevel >= 0) {
            return 'quarter';
        }
        return 'empty';
    };

    const getBatteryColour = batteryLevel => {
        if (batteryLevel >= 75) {
            return 'clr-green';
        }
        if (batteryLevel >= 50) {
            return 'clr-orange';
        }
        return 'clr-red';
    };

    const lastConnectedString = FormatService.lastConnectedString(result.dateTimeConnected);

    const rolescheck = authContext => {
        const { hasSystemAdminRole } = authContext.actions;
        const { hasDeviceManagerRole } = authContext.actions;
        const { hasSimCardManagerRole } = authContext.actions;
        var hasDeviceRole = hasDeviceManagerRole() || hasSystemAdminRole();
        var hasASimRole = hasSystemAdminRole() || hasSimCardManagerRole();
        return [
            {
                hide: !hasDeviceRole,
                simhide: !hasASimRole,
                hasSystemAdminRole: hasSystemAdminRole()
            }
        ];
    };

    function isIC7(type, simDetails, simhide) {
        if (simhide == false) {
            if (type == 'IC7' || type == 'IC7.O') {
                if (simDetails != null && simDetails != undefined) {
                    state.buttonHide = simDetails.isSimCardActive;
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    function activateSIM(deviceId) {
        toggleDeviceSimExpireDateModal(deviceId);
    }

    function deactivateSIM(deviceId) {
        HttpService.post('/SimCard/DeactivateSimCard', { DeviceReference: deviceId }).then(response => {
            if (response.status === 200) {
                if (response.data.commandSucceeded == true) {
                    toggleResponseModal('Sim card deactivated successfully.', response.data.stateDescription);
                } else {
                    toggleResponseModal(
                        'An error occurred while trying to deactivate the sim card, please contact an administrator.',
                        response.data.stateDescription
                    );
                }
            }
        });
    }

    return (
        <>
            <AuthConsumer>
                {context => {
                    return (
                        <>
                            {rolescheck(context).map(permissions => (
                                <TransitionMotion
                                    willLeave={willLeave}
                                    styles={items.map(item => ({
                                        key: item.key,
                                        style: {
                                            maxHeight: item.maxHeight,
                                            opacity: item.opacity,
                                            paddingTop: item.paddingTop,
                                            paddingBottom: item.paddingBottom
                                        }
                                    }))}
                                >
                                    {interpolatedStyles => (
                                        <tr key={`TablePanel${result.id}`} className="table-details-panel">
                                            <td colSpan={colSpan}>
                                                {interpolatedStyles.map(config => {
                                                    return (
                                                        <div className="device-details" key={config.key} style={{ ...config.style }}>
                                                            <div className="device-details-upper">
                                                                <div className="device-status">
                                                                    {/* eslint-disable-next-line  */}
                                                                    <a
                                                                        className={`status-item ${getBatteryColour(
                                                                            result.batteryRemaining
                                                                        )}`}
                                                                    >
                                                                        <i
                                                                            className={`fal fa-battery-${getBatteryIcon(
                                                                                result.batteryRemaining
                                                                            )}`}
                                                                        />
                                                                        {result.batteryRemaining
                                                                            ? `${result.batteryRemaining}%`
                                                                            : 'UNKNOWN'}
                                                                    </a>
                                                                    <div className="status-item">
                                                                        <i className="fal fa-calendar-alt" />
                                                                        {lastConnectedString}
                                                                    </div>
                                                                    <div className="status-item">
                                                                        <i className="fal fa-map-marker-alt" />
                                                                        {result.productLifecycleStage.stageName}
                                                                    </div>
                                                                </div>

                                                                {result.alerts && (
                                                                    <div className="status-alerts">
                                                                        {result.alerts.map((alert, index) => (
                                                                            <div
                                                                                key={`${alert.text}${index}`}
                                                                                className={alert.colourClassName}
                                                                            >
                                                                                <i key={alert.text} className={alert.iconClassName} />{' '}
                                                                                <span style={{ whiteSpace: 'pre-line' }}>{alert.text}</span>
                                                                            </div>
                                                                        ))}
                                                                        {simIcon}
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className="device-details-lower" style={{ textAlign: 'left' }}>
                                                                <div className="device-info">
                                                                    {result.metaDataCategories.length > 0 &&
                                                                        result.metaDataCategories.map(deviceDetailCategory => (
                                                                            <div
                                                                                key={deviceDetailCategory.categoryName}
                                                                                className="device-info-list"
                                                                            >
                                                                                <h5>{deviceDetailCategory.readableName}</h5>
                                                                                <hr />
                                                                                <table className="table">
                                                                                    <tbody>
                                                                                        {deviceDetailCategory.deviceMetaDatas.map(item => (
                                                                                            <tr key={item.key}>
                                                                                                <td>{item.readableName}</td>
                                                                                                <td>
                                                                                                    {item.key === 'TotalTravelTime' ? (
                                                                                                        UtilityService.secondsToHumanise(
                                                                                                            item.value
                                                                                                        )
                                                                                                    ) : item.key === 'DateTimeConnected' ? (
                                                                                                        <Moment local>{item.value}</Moment>
                                                                                                    ) : (
                                                                                                        item.value
                                                                                                    )}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        ))}
                                                                    {result.groups.length > 0 && (
                                                                        <div className="device-info-list">
                                                                            <h5>DEVICE GROUPS</h5>
                                                                            <hr />
                                                                            <table className="table">
                                                                                <tbody>
                                                                                    {sortBy(result.groups, 'groupName').map(
                                                                                        ({ groupName, groupByCustomerName }) => (
                                                                                            <tr key={groupName}>
                                                                                                <td>
                                                                                                    <strong>{groupName}</strong>
                                                                                                    &nbsp;created by&nbsp;
                                                                                                    <strong>{groupByCustomerName}</strong>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    )}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    )}

                                                                    {result.deviceSimCardDetails != null && (
                                                                        <div className="device-info-list">
                                                                            <h5>SIM CARD Details</h5>
                                                                            <hr />
                                                                            <table className="table">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>ICCID</td>
                                                                                        <td>{result.deviceSimCardDetails.iccid}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>ICMI</td>
                                                                                        <td>{result.deviceSimCardDetails.imsi}</td>
                                                                                    </tr>
                                                                                    {result.deviceSimCardDetails.primaryMsisdn != '' && (
                                                                                        <tr>
                                                                                            <td>PrimaryMsisdn</td>
                                                                                            <td>
                                                                                                {result.deviceSimCardDetails.primaryMsisdn}
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                                    {result.deviceSimCardDetails.secondaryMsisdn != '' && (
                                                                                        <tr>
                                                                                            <td>SecondaryMsisdn</td>
                                                                                            <td>
                                                                                                {
                                                                                                    result.deviceSimCardDetails
                                                                                                        .secondaryMsisdn
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <div className="device-actions">
                                                                    {/* 
                                                                        ToDo: https://controlf1.atlassian.net/browse/MDM-57
                                                                        <Link to="" className="btn btn-default">
                                                                            VIEW ERROR LOGS &nbsp; <i className="fal fa-exclamation-circle" />
                                                                        </Link> */}
                                                                    <div hidden={permissions.hide}>
                                                                        <Link
                                                                            to={`EditDevice/${result.id}`}
                                                                            className="btn clr-primary brd-primary btn-border"
                                                                        >
                                                                            EDIT DEVICE &nbsp; <i className="fal fa-cogs" />
                                                                        </Link>
                                                                    </div>
                                                                    <button
                                                                        className="btn brd-primary clr-primary btn-border"
                                                                        onClick={() => toggleDeviceAuditModal(result.id)}
                                                                    >
                                                                        VIEW AUDIT &nbsp; <i className="fal fa-history" />
                                                                    </button>
                                                                    <div
                                                                        hidden={isIC7(
                                                                            result.deviceType,
                                                                            result.deviceSimCardDetails,
                                                                            permissions.simhide
                                                                        )}
                                                                    >
                                                                        <div hidden={state.buttonHide}>
                                                                            <button
                                                                                className="btn brd-green clr-green btn-border "
                                                                                onClick={() => activateSIM(result.deviceId)}
                                                                            >
                                                                                Activate SIM&nbsp; <i className="fal fa-check" />
                                                                            </button>
                                                                        </div>
                                                                        <div hidden={!state.buttonHide}>
                                                                            <button
                                                                                className="btn brd-red clr-red btn-border "
                                                                                onClick={() => deactivateSIM(result.deviceId)}
                                                                            >
                                                                                Deactivate SIM&nbsp; <i className="fal fa-times" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <button
                                                                        className="btn brd-primary clr-primary btn-border"
                                                                        onClick={() => toggleDeviceMetaDataHistoryModal(result.id)}
                                                                    >
                                                                        VIEW METADATA HISTORY &nbsp; <i className="fal fa-history" />
                                                                    </button>
                                                                    <div hidden={!permissions.hasSystemAdminRole}>
                                                                        <button
                                                                            className="btn brd-primary clr-primary btn-border"
                                                                            onClick={() => toggleAnalyticsModal(result.id)}
                                                                        >
                                                                            VIEW ANALYTICS &nbsp; <i className="fal fa-history" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </td>
                                        </tr>
                                    )}
                                </TransitionMotion>
                            ))}
                        </>
                    );
                }}
            </AuthConsumer>
            {displayBatteryUsageModal && (
                <BatteryUsageModal id={result.id} onClose={() => setDisplayBatteryUsageModal(!displayBatteryUsageModal)} />
            )}
        </>
    );
};
